import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataLogin } from "Client";
import api from "api";
import { Row, Col, Image } from "antd";
import Slider from "react-slick";
import ImageCustom from "./image_custom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Comment from "./comment";
import ListItem from "client_component/home/list-item";
import { mainColor } from "config";

const BlogDetail = (props) => {
  const [data, setData] = React.useState({});
  const [category, setCategory] = React.useState({});
  const [listAttachment, setListAttachment] = React.useState([]);
  const [syncFB, setSyncFB] = React.useState(false);
  const [listRelate, setListRelate] = React.useState([]);

  var params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getDataBlog();
  }, []);
  const getDataBlog = () => {
    api
      .get("/ClientBlog/GetBySlug?slug=" + params.slug)
      .then((res) => {
        api.get("/ClientBlog/GetListRelate?blogID=" + res.data.id).then((x) => {
          setListRelate(x.data);
        });
        setData(res.data);
        setCategory(res.category);
        if (res.data.type == "fb_post") {
          var dataAtt = JSON.parse(res.data.attachments);
          setListAttachment(dataAtt?.subattachments?.data ?? []);
        }
      })
      .catch((err) => {
        navigate("/blog");
      });
  };
  const onImageError = async (src) => {
    if (syncFB) return;
    setSyncFB(true);
    var res = await api.get(`/ClientBlog/ReGetDataFB?id=${data.id}&type=detail&oldsrc=${src}`);
    getDataBlog();
    return res;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 500,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="container">
        <Row>
          <Col span={2}></Col>
          <Col span={20}>
            <Row style={{ marginTop: 20 }}>
              <Col span={2}></Col>
              <Col span={20}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                  <div style={{ backgroundColor: "white", padding: "4px 15px", fontSize: 18, borderRadius: 15, whiteSpace: "nowrap" }}>{category.name}</div>
                  <div style={{ backgroundColor: "white", padding: "4px 15px", fontSize: 18, borderRadius: 15, whiteSpace: "nowrap" }}>
                    {data.type == "fb_post" ? "Facebook" : "Website"}
                  </div>
                </div>
                <div style={{ marginBottom: 20, fontSize: 40, fontWeight: "bold", display: "flex", justifyContent: "center", textAlign: "justify" }}>
                  {data.name ? data.name.toUpperCase() : data.name}
                </div>
              </Col>
              <Col span={2}></Col>
            </Row>

            <div style={{ marginBottom: 10, width: "100%" }}>
              <img src={data.image} style={{ aspectRatio: 3 / 2, borderRadius: 20, width: "100%" }} />
            </div>
            <div style={{ marginBottom: 5, display: "flex", justifyContent: "center", fontSize: 18 }}>{data.description}</div>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
              <hr style={{ width: "40%" }} />
            </div>
            <div className="blog_content" dangerouslySetInnerHTML={{ __html: data.content }}></div>
            {data.type == "fb_post" ? <div></div> : <></>}
            <div>
              <Slider {...settings}>
                {listAttachment.map((x) => {
                  return (
                    <div>
                      <div style={{ display: "flex", aspectRatio: 1 / 1, padding: 10 }}>
                        <div
                          style={{
                            margin: "auto",
                            backgroundColor: "black",
                            height: "100%",
                            width: "100%",
                            borderRadius: 20,
                            overflow: "hidden",
                            display: "flex",
                          }}
                        >
                          {x.type == "photo" ? (
                            <ImageCustom
                              src={x.media.image.src}
                              width={x.media.image.width > x.media.image.height ? "100%" : "auto"}
                              height={x.media.image.width > x.media.image.height ? "auto" : "100%"}
                              onError={onImageError}
                            ></ImageCustom>
                          ) : (
                            <video
                              style={x.media.image.width > x.media.image.height ? { width: "100%", margin: "auto" } : { height: "100%", margin: "auto" }}
                              poster={x.media.image.src}
                              src={x.media.source}
                              controls
                            ></video>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <Comment blogID={data.id}></Comment>
          </Col>
          <Col span={2}></Col>
        </Row>
      </div>
      <div>
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 40,
              fontWeight: "bold",
              fontFamily: "CustomFont",
              color: mainColor,
              padding: "50px 0",
            }}
          >
            <div>Khóa học liên quan</div>
            <div
              className="text-hover"
              style={{ fontSize: 25 }}
              onClick={() => {
                navigate("/blog");
              }}
            >
              Xem thêm
            </div>
          </div>
          <div>
            <ListItem type="blog" list={listRelate}></ListItem>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogDetail;
