import * as React from "react";
import api from "api";
import { parseJwt } from "common/jwt/jwt_helper";
import { LogoutOutlined, BlockOutlined, UserAddOutlined, FacebookOutlined, PhoneOutlined, MailOutlined, MenuOutlined } from "@ant-design/icons";
import { Layout, Button, Menu, Avatar, Row, Dropdown, Spin } from "antd";
import { useNavigate, Outlet } from "react-router-dom";
import "./index.css";
import { registerLoading } from "component/common/loading";
import MenuItemPC from "client_component/layout/menu_item_pc";
import { mainColor } from "config";
import MenuMobile from "client_component/layout/menu-mobile";
import LoginButton from "client_component/layout/login-button";
import LoginPopup from "client_component/layout/login-popup";

const { Header, Footer, Sider, Content } = Layout;

export const DataLogin = React.createContext();
const Admin = (props) => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [userLogin, setUserLogin] = React.useState({});
  const [loadingApp, setLoadingApp] = React.useState(false);
  const loginPopup = React.useRef();

  const navigate = useNavigate();
  registerLoading(setLoadingApp);

  React.useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    api
      .get("User/me")
      .then((res) => {
        setIsLogin(true);
        setUserLogin(res.data);
      })
      .catch((err) => {
        setIsLogin(false);
      });
  };

  const logOut = () => {
    api.get("/Login/Logout").then((res) => {
      checkLogin();
    });
  };

  const itemMenu = [
    {
      path: "/about",
      label: "Về Sciphy",
    },

    {
      path: "/course",
      label: "Khóa học",
    },
    {
      path: "/blog",
      label: "Bài viết",
    },
    {
      path: "/document",
      label: "Tài liệu",
    },
  ];

  const footerStyle = {
    textAlign: "center",
    backgroundColor: "#a11f37",
    color: "#fff",
  };

  return (
    <DataLogin.Provider value={{ isLogin: isLogin, userLogin: userLogin }}>
      <div style={{ backgroundColor: "#d7f0ec", width: "100%" }}>
        <div
          style={{
            backgroundColor: mainColor,
            width: "100%",
            height: 100,
            padding: 12,
            fontFamily: "CustomFont",
            boxSizing: "border-box",
            boxShadow: "0px 4px 8px 0px #dddddd",
          }}
        >
          <div className="container" style={{ display: "flex", justifyContent: "space-between", padding: 0 }}>
            <div style={{ height: 60, borderRadius: 5, padding: 7, display: "flex" }}>
              <a href="/" style={{ margin: "auto", display: "flex" }}>
                <img src="/image/logo2.png" style={{ height: 46, margin: "auto" }} alt="" />
              </a>
            </div>
            <div className="hide-in-mobile" style={{ display: "flex" }}>
              {itemMenu.map((x) => {
                return <MenuItemPC data={x}></MenuItemPC>;
              })}
            </div>
            <div className="hide-in-mobile">
              <LoginButton loginPopup={loginPopup} isLogin={isLogin} userLogin={userLogin} logOut={logOut} />
            </div>

            <MenuMobile data={itemMenu} loginPopup={loginPopup} logOut={logOut} />
          </div>
        </div>
        <div>
          <Outlet />
        </div>

        <div style={{ width: "100%", backgroundColor: mainColor, padding: 5, marginTop: 20, boxSizing: "border-box" }}>
          <div className="container block-mobile" style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <MailOutlined style={{ fontSize: 30, color: "white", marginTop: "auto", marginBottom: "auto" }} />
                  <div style={{ paddingLeft: 5, marginTop: "auto", marginBottom: "auto" }}>
                    <a
                      style={{ fontWeight: "bold", color: "white", textDecoration: "none" }}
                      href="Sciphy.network@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sciphy.network@gmail.com
                    </a>
                  </div>
                </div>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <PhoneOutlined style={{ fontSize: 30, color: "white", marginTop: "auto", marginBottom: "auto" }} />
                  <div style={{ paddingLeft: 5, fontWeight: "bold", color: "white", marginTop: "auto", marginBottom: "auto" }}>0865 284 817</div>
                </div>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <FacebookOutlined style={{ fontSize: 30, color: "white", marginTop: "auto", marginBottom: "auto" }} />
                  <div style={{ paddingLeft: 5, marginTop: "auto", marginBottom: "auto" }}>
                    <a
                      style={{ fontWeight: "bold", color: "white", textDecoration: "none" }}
                      href="https://www.facebook.com/sciphy2023"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.facebook.com/sciphy2023
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="hide-in-mobile" style={{ display: "flex", marginLeft: 10, fontFamily: "CustomFont" }}>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "bold", fontSize: 30, color: "white" }}>SCIPHY SINCE 2023 </div>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <img src="/image/Mascot2.png" alt="" style={{ height: 100 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginPopup ref={loginPopup} checkLogin={checkLogin}></LoginPopup>
      </div>
    </DataLogin.Provider>
  );
};

export default Admin;
