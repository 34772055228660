var { api_url, secret } = require("config");
var CryptoJS = require("crypto-js");
var axios = require("axios");

var axiosInstance = axios.create({
  baseURL: api_url,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  function (response) {
    var data = response.data;
    if (!data.Error) {
      if (data.code == 1) {
        return data;
      } else {
        console.error("api error: ", data.Error);
        return Promise.reject(data);
      }
    } else {
      if (data.Error.Message == "Missing token") {
        console.log("Missing token")
        var href = window.location.pathname;
        if (href.includes("admin")) window.location = "/admin/login";
        else {
          var urlRequest = response.config.url.toLowerCase();
          if (urlRequest != "/user/me" && urlRequest != "user/me") {
            window.location = "/";
          }
        }
      } else {
        console.error("api error: ", data.Error);
        return Promise.reject(data);
      }
    }
  },
  function (error) {
    console.error("api error: ", error);
    return Promise.reject(error);
  }
);

axiosInstance.setTokenKey = function (key) {
  axiosInstance._TokenKey = key;
};

export default axiosInstance;
