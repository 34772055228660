import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Space, message, Row, Col, Select, Input, Tag } from "antd";
import PopupEditHomework from "./popup_edit_homework";
import { EditOutlined, DeleteOutlined, PlusOutlined, RotateRightOutlined, SearchOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import realtime from "common/Realtime";

const Homework = (props) => {
  const [filter, setFilter] = React.useState({});
  const homeworkPopup = React.useRef();
  const tbHomework = React.useRef();

  React.useEffect(() => {
    getLstHomework();
  }, []);

  const getLstHomework = () => {
    tbHomework.current.reload();
  };

  const deletehomework = (id) => {
    api.get("Homework/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getLstHomework();
    });
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Chủ đề",
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Đường dẫn",
      dataIndex: "url",
      key: "url",
      render: (text, record, index) => {
        return (
          <a href={text} target="_blank">
            {text}
          </a>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              homeworkPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                homeworkPopup.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              title="Bạn có muốn xóa lớp học này không"
              placement="topRight"
              onConfirm={() => {
                deletehomework(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.status}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
                onPressEnter={() => {
                  getLstHomework();
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstHomework();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable columns={col} ajax="Homework/GetList" customSearch={filter} ref={tbHomework}></AntTable>
        <PopupEditHomework ref={homeworkPopup} reload={getLstHomework}></PopupEditHomework>
      </Card>
    </>
  );
};

export default Homework;
