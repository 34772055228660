import React from "react";
import { Input, Dropdown, Divider, theme } from "antd";
import api from "api";

const { useToken } = theme;

const FilterSelect = (props) => {
  const [listOption, setListOption] = React.useState([]);
  const [value, setValue] = React.useState();
  const [displayValue, setDisplayValue] = React.useState();
  const timeoutID = React.useRef();

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  React.useEffect(() => {
    api.get(props.url).then((res) => {
      compareOption(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (props.value)
      api.get(props.getListByIDURL + props.value.toString()).then((res) => {
        setValue(res.data[props.fieldNames.value]);
        setDisplayValue(res.data[props.fieldNames.label]);
        var check = listOption.find((x) => x[props.fieldNames.value] == res.data[props.fieldNames.value]);
        if (!check) {
          var lst = [...listOption];
          lst.push(res.data);
          setListOption(lst);
        }
      });
  }, [props.value]);

  const compareOption = (lstOption) => {
    setListOption(lstOption);
  };

  const search = (e) => {
    if (timeoutID.current) clearTimeout(timeoutID.current);
    timeoutID.current = setTimeout(() => {
      api.get(props.url + e).then((res) => {
        compareOption(res.data);
        clearTimeout(timeoutID);
      });
    }, 500);
  };

  const onClick = (item) => {
    var data = listOption.find((x) => x[props.fieldNames.value] == item.key);
    setValue(data[props.fieldNames.value]);
    setDisplayValue(data[props.fieldNames.label]);
    if (props.onChange) props.onChange(data[props.fieldNames.value]);
  };

  const onClear = () => {
    setValue(null);
    setDisplayValue("");
    if (props.onChange) props.onChange(null);
  };

  return (
    <Dropdown
      menu={{
        items: listOption.map((x) => {
          return {
            key: x[props.fieldNames.value],
            label: (
              <div
                style={
                  x[props.fieldNames.value] == value
                    ? { width: "100%", backgroundColor: "#1677ff", padding: 5, borderRadius: 5 }
                    : { width: "100%", padding: 5, borderRadius: 5 }
                }
              >
                {x[props.fieldNames.label]}
              </div>
            ),
          };
        }),
        onClick: onClick,
      }}
      dropdownRender={(menu) => (
        <div style={{ ...contentStyle, padding: 5, maxHeight: 300, overflowY: "scroll" }}>
          <div style={{ marginBottom: 10 }}>
            <Input
              size="large"
              onChange={(e) => {
                search(e.target.value);
              }}
            ></Input>
          </div>
          <Divider
            style={{
              margin: 0,
            }}
          />
          <div>
            {React.cloneElement(menu, {
              style: menuStyle,
            })}
          </div>
        </div>
      )}
    >
      <Input
        value={displayValue}
        allowClear
        onChange={(e) => {
          if (e.target.value == "") onClear();
        }}
      ></Input>
    </Dropdown>
  );
};

export default FilterSelect;
