import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, DatePicker, Select, Modal, Upload, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { imageURL, uploadImageURL } from "config/index";
import UploadImagePicker from "component/picker/image_picker";

const { Option } = Select;
const { TextArea } = Input;
const UserPopup = React.forwardRef((props, ref) => {
  const [data, setData] = React.useState({});
  const [visible, setVisible] = React.useState();
  const [lstrole, setlstrole] = React.useState([]);
  const form = React.useRef();
  const [mode, setmode] = React.useState("edit");

  const [defaultImageURL, setdefaultImageURL] = React.useState([]);
  React.useEffect(() => {
    getlstrole();
  }, [props.reload]);

  const getlstrole = () => {
    api.get("User/GetListRole").then((res) => {
      setlstrole(res.lstrole.filter((x) => x.id != 4));
    });
  };

  const saveUser = (values) => {
    values.social_insurance_begin = values.social_insurance_begin?.format();
    values.birthday = values.birthday?.format();
    values.role_id = values.role_id ?? 4;
    const data = { user: values, mode };
    api
      .post("User/SaveUser", data)
      .then((res) => {
        message.success(res.message);
        if (props.reload) props.reload();
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(user) {
      setVisible(true);
      setTimeout(() => {
        if (user) {
          setmode("edit");

          api.get("/user/GetByUsername?username=" + user.user_name).then((res) => {
            res.data.birthday = res.data.birthday ? dayjs(res.data.birthday) : null;
            setData(res.data);
            form.current.setFieldsValue(res.data);
          });
        } else {
          setmode("add");
          form.current.resetFields();
          setdefaultImageURL([]);
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      width="50%"
      open={visible}
      title={mode == "add" ? `Thêm mới ${props.type == "staff" ? "nhân viên" : "học sinh"}` : `Chỉnh sửa ${props.type == "staff" ? "nhân viên" : "học sinh"}`}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
    >
      <Form ref={form} onFinish={saveUser} name="validateOnly" layout="vertical">
        <Row gutter={16}>
          {props.type == "staff" ? (
            <Col span={24}>
              <Form.Item label="Avatar" name="avatar" rules={[{ required: true, message: "Nhập hình ảnh!" }]}>
                <UploadImagePicker crop={{ quality: 1, aspect: 3 / 3 }} size={{ width: 300, height: 300 }} tags={["logo"]}></UploadImagePicker>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          <Col span={12}>
            <Form.Item label="Tên" name="name" rules={[{ required: true, message: "Nhập tên!" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tên đăng nhập" name="user_name" rules={[{ required: true, message: "Nhập tên đăng nhập!" }]}>
              <Input disabled={mode == "edit" ? true : false} />
            </Form.Item>
          </Col>

          <Col span={12} style={mode == "edit" && { display: "none" }}>
            <Form.Item label="Mật khẩu" name="password" rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}>
              <Input.Password value={1234567890} />
            </Form.Item>
          </Col>
          {props.type == "staff" ? (
            <>
              <Col span={12}>
                <Form.Item name="role_id" label="Quyền" rules={[{ required: true, message: "Chọn quyền!" }]}>
                  <Select
                    disabled={data.user_name == "admin"}
                    placeholder="Quyền"
                    allowClear
                    options={lstrole.map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="cv_link" label="CV" rules={[{ required: true, message: "Nhập link CV" }]}>
                  <Input></Input>
                </Form.Item>
              </Col>
            </>
          ) : (
            <></>
          )}

          <Col span={12}>
            <Form.Item name="gender" label="Giới tính" rules={[{ required: true, message: "Chọn giới tính!" }]}>
              <Select placeholder="Giới tính" allowClear>
                <Option value="male">Nam</Option>
                <Option value="female">Nữ</Option>
                <Option value="other">Khác</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phone_number" label="Số điện thoại">
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Sinh nhật " name="birthday">
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Địa chỉ " name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Facebook" name="fb_link">
              <Input />
            </Form.Item>
          </Col>
          {props.type == "student" ? (
            <>
              <Col span={12}>
                <Form.Item label="Trường" name="school">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Lớp" name="class">
                  <InputNumber max={12} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Khả năng" name="ability">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Ms Teams" name="ms_teams">
                  <Input />
                </Form.Item>
              </Col>
            </>
          ) : (
            <></>
          )}
          <Col span={24}>
            <Form.Item label="Mô tả " name="description">
              <TextArea rows={4} style={{ marginLeft: "5px" }} />
            </Form.Item>
          </Col>
          {props.type == "student" ? (
            <Col span={24}>
              <Form.Item label="Mong đợi" name="expect">
                <TextArea rows={4} style={{ marginLeft: "5px" }} />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Form>
    </Modal>
  );
});
export default UserPopup;
