import * as React from "react";
import api from "api";
import { Select, Input, Form, message, Modal, Table, Row, Col, InputNumber, Checkbox, Card, DatePicker } from "antd";
import { setAppLoading } from "component/common/loading";
import dayjs from "dayjs";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import UploadImagePicker from "component/picker/image_picker";
import Slug from "component/picker/slug_picker";
import { toNoUnicode } from "common/Text";
import { useNavigate } from "react-router-dom";
import HTMLEditorPicker from "component/picker/html_editor_picker";
import { myDomain } from "config";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const PopupEditClass = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [listUser, setListUser] = React.useState([]);
  const [listShift, setListShift] = React.useState([]);
  const [lstTarget, setListTarget] = React.useState([]);
  const form = React.useRef();

  const navigate = useNavigate();

  const saveClass = (values, lst) => {
    values.start_date = values.time ? values.time[0].format() : null;
    values.finish_date = values.time ? values.time[1].format() : null;
    api
      .post("/Class/Update", {
        Data: values,
        UserIDs: values.user_ids,
        LstSchedule: lst.filter((x) => x.monday || x.tuesday || x.wednesday || x.thursday || x.friday || x.saturday || x.sunday),
      })
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
        setListShift([]);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    props.reload();
    setVisible(false);
  };

  const getlstuser = () => {
    api.get("User/GetListTeacher").then((res) => {
      setListUser(res.data);
    });
  };

  const getListTarget = () => {
    api.get("Target/GetList").then((res) => {
      setListTarget(res.data);
      setAppLoading(false);
    });
  };

  const chooseDate = (id, name, value) => {
    var lst = [...listShift];
    var item = listShift.find((x) => x.shift_id == id);
    if (item) item[name] = value;
    setListShift(lst);
  };

  const nameChange = (text) => {
    form.current.setFieldsValue({ slug: toNoUnicode(text, true) });
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setAppLoading(true);
      setVisible(true);
      setTimeout(() => {
        getlstuser();
        getListTarget();
        if (id) {
          api.get("/Class/GetByID?id=" + id).then((res) => {
            if (res.data.start_date && res.data.finish_date) res.data.time = [dayjs(res.data.start_date), dayjs(res.data.finish_date)];
            res.data.user_ids = res.listTeacher.map((x) => x.user_id);
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({ status: "draft" });
        }
      }, 100);
    },
  }));

  const col = [
    {
      title: "Ca",
      dataIndex: "shift_name",
      key: "shift_name",
    },
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time",
      render: (text, record, index) => {
        return dayjs(record.start_time).format("HH:mm") + " => " + dayjs(record.end_time).format("HH:mm");
      },
    },
    {
      title: "Thứ 2",
      dataIndex: "monday",
      key: "monday",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.monday}
            onChange={(e) => {
              chooseDate(record.shift_id, "monday", e.target.checked);
            }}
          />
        );
      },
    },
    {
      title: "Thứ 3",
      dataIndex: "tuesday",
      key: "tuesday",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.tuesday}
            onChange={(e) => {
              chooseDate(record.shift_id, "tuesday", e.target.checked);
            }}
          />
        );
      },
    },
    {
      title: "Thứ 4",
      dataIndex: "wednesday",
      key: "wednesday",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.wednesday}
            onChange={(e) => {
              chooseDate(record.shift_id, "wednesday", e.target.checked);
            }}
          />
        );
      },
    },
    {
      title: "Thứ 5",
      dataIndex: "thursday",
      key: "thursday",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.thursday}
            onChange={(e) => {
              chooseDate(record.shift_id, "thursday", e.target.checked);
            }}
          />
        );
      },
    },
    {
      title: "Thứ 6",
      dataIndex: "friday",
      key: "friday",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.friday}
            onChange={(e) => {
              chooseDate(record.shift_id, "friday", e.target.checked);
            }}
          />
        );
      },
    },
    {
      title: "Thứ 7",
      dataIndex: "saturday",
      key: "saturday",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.saturday}
            onChange={(e) => {
              chooseDate(record.shift_id, "saturday", e.target.checked);
            }}
          />
        );
      },
    },
    {
      title: "CN",
      dataIndex: "sunday",
      key: "sunday",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.sunday}
            onChange={(e) => {
              chooseDate(record.shift_id, "sunday", e.target.checked);
            }}
          />
        );
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa khóa học"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="90%"
    >
      <Row style={{ display: "flex" }} gutter={20}>
        <Col span={24}>
          <Card>
            <Form
              ref={form}
              onFinish={(values) => {
                saveClass(values, listShift);
              }}
              layout="vertical"
            >
              <Form.Item name="id" hidden></Form.Item>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item label="Tên " name="name" rules={[{ required: true, message: "Nhập tên khóa học!" }]}>
                    <Input
                      onChange={(e) => {
                        nameChange(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Slug " name="slug" rules={[{ required: true, message: "Nhập slug!" }]}>
                    <Slug />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Hình ảnh" name="image" rules={[{ required: true, message: "Nhập hình ảnh!" }]}>
                    <UploadImagePicker crop={{ quality: 1, aspect: 3 / 2 }} size={{ width: 300, height: 200 }} tags={["logo"]}></UploadImagePicker>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mô tả ngắn" name="description">
                    <TextArea rows={8} wrap="off" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Nội dung" name="content">
                    <HTMLEditorPicker></HTMLEditorPicker>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Trạng thái" name="status">
                    <Select
                      style={{ marginLeft: "5px" }}
                      options={[
                        { value: "draft", label: "Bản nháp" },
                        { value: "publish", label: "Đang hoạt động" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Mục tiêu" name="target_id" rules={[{ required: true, message: "Chọn mục tiêu!" }]}>
                    <Select fieldNames={{ label: "name", value: "id" }} options={lstTarget} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Giáo viên" name={"user_ids"} rules={[{ required: true, message: "Chọn giáo viên!" }]}>
                    <Select mode="multiple" style={{ marginLeft: "5px" }} options={listUser} fieldNames={{ label: "name", value: "user_name" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Thời gian" name={"time"} rules={[{ required: true, message: "Chọn thời gian!" }]}>
                    <RangePicker />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Video học thử " name="demo_video">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={"Hoa hồng"} name={"percent_wage"} rules={[{ required: true, message: "Nhập hoa hồng!" }]}>
                    <InputNumber style={{ width: "100%" }} min={0} max={100}></InputNumber>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label={"Học phí"} name={"tuition"} rules={[{ required: true, message: "Nhập học phí!" }]}>
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      step={1000}
                    ></InputNumber>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label={"Số lần thu học phí"} name={"collection_times"} rules={[{ required: true, message: "Nhập số lần thu học phí!" }]}>
                    <InputNumber style={{ width: "100%" }}></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
});
export default PopupEditClass;
