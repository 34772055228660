import React from "react";
import api from "api";
import { Row, Col, Image, Card } from "antd";
import { mainColor } from "config";

const About = (props) => {
  const [listMission, setListMisson] = React.useState([]);

  React.useEffect(() => {
    api.get("/About/GetMission").then((res) => {
      setListMisson(res.data);
    });
  }, []);

  return (
    <div>
      <div style={{ padding: "50px 0", backgroundImage: `linear-gradient(to right, ${mainColor} , #87d1d1)` }}>
        <div className="container">
          <Row gutter={[50, 20]}>
            <Col xs={24} sm={24} md={9} lg={9} xl={9}>
              <div style={{ display: "flex", width: "100%", height: "100%" }}>
                <img
                  src="https://cdn.britannica.com/84/182384-050-E6EC1625/prism-Isaac-Newton-sunlight-experiment-band-colors.jpg"
                  style={{ aspectRatio: 3 / 4, width: "100%", margin: "auto", borderRadius: 20 }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                <div style={{ fontFamily: "CustomFont", fontSize: 30, textAlign: "center" }}>
                  Sciphy: Bước cùng thế hệ trẻ trên con đường chinh phục niềm đam mê Vật Lý
                </div>
              </div>
              <Card style={{ fontSize: 15, textAlign: "justify", boxShadow: "0px 4px 8px 0px #dddddd" }}>
                <div>
                  Tổng quan về Sciphy Khi tham gia các cuộc thi học sinh giỏi và đặc biệt là bộ môn Vật Lý, có phải bạn cảm thấy mông lung giữa vô vàn kiến thức
                  khó nhằn, không biết nên bắt đầu từ đâu, xây dựng lộ trình thế nào?
                </div>
                <div>
                  Có phải bạn cảm thấy cô độc trên con đường gian nan ấy khi không có người hướng dẫn cùng sát cánh vì đôi khi giáo viên chưa thể toàn tâm vì
                  nhiều lý do khác?
                </div>
                <div>
                  {" "}
                  Cùng với những trăn trở đó, Sciphy được xây dựng trở thành một cộng đồng kết nối giữa các anh chị đã đạt được thành tích cao trong các cuộc
                  thi học sinh giỏi Vật Lý và các bạn học sinh đang trên con đường theo niềm đam mê với môn học này, qua đó nhằm ươm mầm cho các bạn học sinh
                  phát triển, tiến xa trên con đường theo đuổi mơ ước của mình.
                </div>
                <div>Sứ mệnh của chúng mình</div>
                <div>
                  Là những học sinh trưởng thành từ các cuộc thi học thuật về Vật Lý, Sciphy thấu hiểu những khó khăn và thách thức ấy hơn bao giờ hết và chúng
                  mình ở đây với sứ mệnh truyền cảm hứng, đồng hành và cùng thế hệ trẻ vững bước tiến về phía trước, không còn mông lung, lạc lối trên con đường
                  học thuật, trang bị cho các bạn nền tảng kiến thức vững chắc để chinh phục các đấu trường Vật Lý.
                </div>
                <div>Tầm nhìn của Sciphy</div>
                <div>
                  Với mong muốn kiến tạo cộng đồng người trẻ đam mê Vật Lý, Sciphy hướng đến một trong những tổ chức uy tín, chuyên nghiệp và năng động nhất về
                  việc kết nối thế hệ đi trước để đào tạo học sinh đạt được kết quả tốt. Bên cạnh việc đem đến kiến thức và các phương pháp thiết thực ở bộ môn
                  Vật Lý, Sciphy phấn đấu, phát triển và xây dựng cộng đồng ở các bộ môn Khoa học tự nhiên trong tương lai.
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container" style={{ marginBottom: 50 }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "center", fontSize: 30, padding: 50, fontWeight: "bold" }}>MISSION</div>
        <Row gutter={60} style={{ marginRight: 30 }}>
          {listMission.map((x) => {
            return (
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div style={{ width: "100%", backgroundColor: "#fff", boxShadow: "0px 4px 8px 0px #dddddd" }}>
                  <div style={{ aspectRatio: 4 / 3, width: "100%" }}>
                    <img src={x.image} style={{ marginTop: -30, marginLeft: 30, aspectRatio: 4 / 3, width: "100%", boxShadow: "0px 4px 8px 0px #dddddd" }} />
                  </div>
                  <div style={{ padding: "0 20px 30px 20px" }}>
                    <div style={{ fontSize: 30, fontWeight: "bold" }}>{x.name}</div>
                    <div style={{ textAlign: "justify" }}>{x.description}</div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
export default About;
