import * as React from "react";
import api from "api";
import { Popconfirm, Button, Table, Space, Card, message } from "antd";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import PopupEditAchievement from "./popup_edit_achievement";

const Achievement = (props) => {
  const [listAchievement, setListAchievement] = React.useState([]);
  const popupEditAchievement = React.useRef();

  React.useEffect(() => {
    getlistAchievement();
  }, [props.reload]);

  const getlistAchievement = () => {
    api.get("Achievement/GetList").then((res) => {
      setListAchievement(res.data);
    });
  };

  const deleteAchievement = (id) => {
    api.get("Achievement/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getlistAchievement();
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              popupEditAchievement.current.open();
            }}
          >
            <PlusOutlined />
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                popupEditAchievement.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa thành tích này không"
              placement="topRight"
              onConfirm={() => {
                deleteAchievement(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Table style={{ width: "100%" }} columns={col} dataSource={listAchievement} rowKey="id" />

      <PopupEditAchievement ref={popupEditAchievement} reload={getlistAchievement}></PopupEditAchievement>
    </Card>
  );
};

export default Achievement;
