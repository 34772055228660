import * as React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import "../../index.css";
import { MenuOutlined } from "@ant-design/icons";
import LoginButton from "./login-button";
import MenuItemMobile from "./menu-item-mobile";
import { mainColor } from "config";
import { Button } from "antd";

const MenuMobile = (props) => {
  const [color, setColor] = React.useState("white");
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (window.location.pathname == props.data.path) {
      setColor("pink");
    } else setColor("white");
  }, [window.location.pathname]);

  return (
    <div className="hide-in-pc">
      <div
        style={
          visible
            ? { backgroundColor: "pink", margin: "auto", padding: 2, cursor: "pointer", border: "2px white solid", borderRadius: 10, fontWeight: "bold" }
            : { margin: "auto", padding: 2, cursor: "pointer", border: "2px white solid", borderRadius: 10, fontWeight: "bold" }
        }
        onClick={() => {
          setVisible((curent) => !curent);
        }}
      >
        <MenuOutlined style={{ fontSize: 30, color: "white" }} />
      </div>
      <div className={visible ? "show" : "hide"}>
        <div
          className="overlay"
          onClick={() => {
            setVisible(false);
          }}
        ></div>
      </div>
      <div className={`popup-menu ${visible ? "open" : ""}`}>
        <div style={{ display: "flex", padding: 20 }}>
          <div style={{ margin: "auto" }}>
            <LoginButton
              loginPopup={props.loginPopup}
              closeParent={() => {
                setVisible((curent) => !curent);
              }}
            />
          </div>
        </div>
        {props.data.map((x) => {
          return <MenuItemMobile data={x}></MenuItemMobile>;
        })}
        <div style={{ display: "flex", padding: 20 }}>
          <Button
            onClick={() => {
              props.logOut();
            }}
            size="large"
            style={{
              width: 200,
              fontFamily: "CustomFont",

              backgroundColor: "white",
              color: mainColor,
              borderRadius: 50,
              fontSize: 20,
              margin: "auto",
              border: "2px solid #9d0101",
              boxSizing: "border-box",
            }}
          >
            Đăng xuất
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
