import * as React from "react";
import { mainColor } from "config";
import { Button, Dropdown, Avatar } from "antd";
import { LogoutOutlined, ReadOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import UserPopup from "component/user/user_popup";
import { useNavigate } from "react-router-dom";
import { DataLogin } from "Client";
import "../../index.css";

const LoginButton = (props) => {
  const userPopup = React.useRef();
  const avatarMenu = [
    {
      icon: <UserAddOutlined />,
      label: "Thông tin tài khoản",
      key: "userinfo",
    },
    {
      icon: <ReadOutlined />,
      label: "Khóa học của tôi",
      key: "my-course",
    },
    {
      icon: <LogoutOutlined />,
      label: "Đăng xuất",
      key: "logout",
    },
  ];
  const navigate = useNavigate();
  const dataLogin = React.useContext(DataLogin);

  const handleAvatarMenu = (e) => {
    switch (e.key) {
      case "logout":
        props.logOut();
        break;
      case "my-course":
        navigate("/my-course");
        break;
      case "userinfo":
        userPopup.current.open(dataLogin.userLogin);
        break;
    }
  };

  return (
    <>
      {props.isLogin ? (
        <div style={{ display: "flex" }}>
          <div style={{ margin: "auto" }}>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: avatarMenu,
                onClick: (e) => {
                  handleAvatarMenu(e);
                },
              }}
            >
              <div style={{ cursor: "pointer", marginLeft: 20 }}>
                <Avatar style={{ backgroundColor: "#87d068" }} icon={<UserOutlined />} />
                <span style={{ color: "#fff", marginLeft: 3, fontSize: 20 }}>{props.userLogin?.user_name.split("@")[0]}</span>
              </div>
            </Dropdown>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", padding: 5 }}>
            <Button
              style={{
                fontFamily: "CustomFont",

                margin: "auto",
                height: 40,
                border: "2px white solid",
                borderRadius: 20,
                color: "white",
                fontWeight: "bold",
                fontSize: 18,
                backgroundColor: mainColor,
                width: 100,
              }}
              onClick={() => {
                if (props.closeParent) props.closeParent();
                props.loginPopup.current.open();
              }}
            >
              Log in
            </Button>
          </div>
          <div style={{ display: "flex", padding: 5 }}>
            <Button
              style={{
                fontFamily: "CustomFont",

                margin: "auto",
                height: 40,
                border: `2px #48cec9 solid`,
                borderRadius: 20,
                color: mainColor,
                fontWeight: "bold",
                fontSize: 18,
                backgroundColor: "white",
                width: 100,
              }}
              onClick={() => {
                if (props.closeParent) props.closeParent();
                props.loginPopup.current.open("signup");
              }}
            >
              Sign up
            </Button>
          </div>
        </div>
      )}
      {dataLogin.isLogin ? <UserPopup ref={userPopup} type={dataLogin.userLogin.role_id == 4 ? "student" : "staff"}></UserPopup> : <></>}
    </>
  );
};
export default LoginButton;
