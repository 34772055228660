import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Table, Space, message } from "antd";
import PopupEditMission from "./popup_mission";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
const Mission = (props) => {
  const [lstMission, setlstMission] = React.useState([]);
  const missionPopup = React.useRef();

  React.useEffect(() => {
    getlstMission();
  }, [props.reload]);

  const getlstMission = () => {
    api.get("Mission/GetList").then((res) => {
      setlstMission(res.data);
    });
  };

  const deleteMission = (id) => {
    api.get("Mission/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getlstMission();
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      key: "image",
      render: (text, record, index) => {
        if (record.image) {
          return <img src={record.image} style={{ width: 150, height: 100 }} />;
        }
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              missionPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                missionPopup.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa mục tiêu này không"
              placement="topRight"
              onConfirm={() => {
                deleteMission(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Table style={{ width: "100%" }} columns={col} dataSource={lstMission} rowKey="id" />
      <PopupEditMission ref={missionPopup} reload={getlstMission}></PopupEditMission>
    </Card>
  );
};

export default Mission;
