import React from "react";
import api from "api";
import { Collapse, Row, Col } from "antd";
import { mainColor } from "config";

const ClientFaq = (props) => {
  const [listFaq, setListFaq] = React.useState([]);

  React.useEffect(() => {
    api.get("/ClientFaq/GetList").then((res) => {
      setListFaq(
        res.data.map((x, index) => {
          return {
            key: x.id,
            label: (
              <div style={{ fontSize: "1.125rem", color: mainColor, lineHeight: "145%", fontWeight: "600" }}>
                <b>{`${index + 1}. ${x.question}`}</b>
              </div>
            ),
            children: (
              <div className="class-content" dangerouslySetInnerHTML={{ __html: x.answer }} style={{ color: "rgb(102 102 148)", fontSize: "1rem" }}></div>
            ),
          };
        })
      );
    });
  }, []);

  return (
    <div>
      <div className="container" style={{ padding: 20, boxSizing: "border-box" }}>
        <div style={{ padding: 30, boxSizing: "border-box", display: "flex" }}>
          <div
            style={{
              margin: "auto",
              fontSize: 40,
              fontWeight: "bold",
              fontFamily: "CustomFont",
              color: mainColor,
            }}
          >
            FAQS
          </div>
        </div>
        <Row>
          <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            {listFaq.map((x) => {
              return <Collapse accordion bordered={false} items={[x]} expandIconPosition={"end"} style={{ marginBottom: 20 }} />;
            })}
          </Col>
          <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
        </Row>
      </div>
    </div>
  );
};

export default ClientFaq;
