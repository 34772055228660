const api_url = window.config.api_url;
const imageURL = window.config.imageURL;
const uploadImageURL = window.config.uploadImageURL;
const myDomain = window.config.myDomain;
const videoURL = window.config.videoURL;
const documentURL = window.config.documentURL;

// const api_url = "http://localhost:1001/api";
// const imageURL = "http://localhost:1001/Files/Images/";
// const videoURL = "http://localhost:1001/Files/Videos/";
// const documentURL = "http://localhost:1001/Files/Documents/";
// const uploadImageURL = "http://localhost:1001/api/user/Image";
// const myDomain = "http://localhost:3000";

const permission = [
  {
    role: 1,
    name: "admin",
    menu: "ALL",
    defaultMenu: "/admin/user",
  },
  {
    role: 2,
    name: "support",
    menu: "ALL",
    defaultMenu: "/admin/user",
  },
  {
    role: 3,
    name: "Giáo viên",
    menu: "ALL",
    defaultMenu: "/admin/student",
  },
];

const mainColor = "#0B8989";

export { api_url, imageURL, uploadImageURL, myDomain, permission, mainColor, videoURL, documentURL };
