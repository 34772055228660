import { mainColor } from "config";
import React from "react";
import { formatShortDate } from "common/Format2";
import { useNavigate } from "react-router-dom";
import ImageCustom from "client_component/blog/image_custom";
import api from "api";

const BlogCard = (props) => {
  const navigate = useNavigate();

  const onImageError = async (src) => {
    var res = await api.get(`/ClientBlog/ReGetDataFB?id=${props.data.id}&type=list&oldsrc=${src}`);

    return res;
  };

  return (
    <div style={{ width: "100%", borderRadius: 10, overflow: "hidden", boxShadow: "0px 4px 8px 0px #dddddd" }}>
      <div
        style={{ aspectRatio: 3 / 2, overflow: "hidden" }}
        onClick={() => {
          navigate(`/blog/${props.data.slug}`);
        }}
      >
        <ImageCustom
          width="100%"
          src={props.data.image}
          style={{ width: "100%" }}
          className="image-hover"
          onClick={() => {
            navigate(`/blog/${props.data.slug}`);
          }}
          onError={onImageError}
          preview={false}
        ></ImageCustom>
      </div>
      <div
        style={{
          width: "100%",
          padding: 20,
          backgroundColor: "#f5f5f5",
          height: 120,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Geomanist,sans-serif",
        }}
      >
        <div
          className="text-hover text-line-2"
          style={{ fontSize: "1.125rem", color: mainColor, lineHeight: "145%", fontWeight: "600", flex: 1 }}
          onClick={() => {
            navigate(`/blog/${props.data.slug}`);
          }}
        >
          {props.data.name}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", color: mainColor, flexWrap: "wrap" }}>
          <div style={{ backgroundColor: "white", padding: "4px 15px", fontSize: 15, borderRadius: 15, whiteSpace: "nowrap" }}>{props.data.category_name}</div>
          <div style={{ backgroundColor: "white", padding: "4px 15px", fontSize: 15, borderRadius: 15, whiteSpace: "nowrap" }}>
            {props.data.type == "fb_post" ? "Facebook" : "Website"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
