import React from "react";
import { useParams } from "react-router-dom";
import api from "api";
import { useNavigate } from "react-router-dom";
import { mainColor } from "config";
import { Row, Col, Button, Tabs, Card, Tooltip, ConfigProvider, message, Tag } from "antd";
import {
  DownloadOutlined,
  VideoCameraOutlined,
  TagOutlined,
  UserOutlined,
  CalendarOutlined,
  DollarOutlined,
  LaptopOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { formatLongDate, formatShortDate, formatCurrency } from "common/Format2";
import { DataLogin } from "Client";
import dayjs from "dayjs";
import ListItem from "client_component/home/list-item";

const ClassDetail = (props) => {
  const [data, setData] = React.useState({});
  const [target, setTarget] = React.useState({});
  const [listTeacher, setListTeacher] = React.useState([]);
  const [listLesson, setListLesson] = React.useState([]);
  const [listLessonPro, setListLessonPro] = React.useState([]);
  const [listAttendace, setListAttendance] = React.useState([]);
  const [listRelate, setListRelate] = React.useState([]);
  const [tabKey, setTabKey] = React.useState("1");
  const [userLinkClass, setUserLinkClass] = React.useState(false);
  var params = useParams();
  const navigate = useNavigate();
  const dataLogin = React.useContext(DataLogin);

  const [tabs, setTabs] = React.useState([
    {
      key: "1",
      label: "Giới thiệu",
    },
    {
      key: "3",
      label: (
        <a href="#teacher" style={{ color: "#000" }}>
          Giảng viên
        </a>
      ),
    },
    {
      key: "4",
      label: "Video học thử",
    },
  ]);

  React.useEffect(() => {
    api
      .get("/ClientClass/GetBySlug?slug=" + params.slug)
      .then((res) => {
        setData(res.data);
        setTarget(res.target);
        setListTeacher(res.listTeacher);
        setListLesson(res.listLesson);
        if (dataLogin.isLogin) {
          getData(res.data.id);
        }
      })
      .catch((err) => {
        navigate("/course");
      });
  }, []);

  React.useEffect(() => {
    if (dataLogin.isLogin && data.id) {
      getData(data.id);
    }
  }, [dataLogin.isLogin, data]);

  const getData = (id) => {
    api.get(`/ClientClass/GetUserLinkClass?classID=` + id).then((res) => {
      if (res.data) {
        setUserLinkClass(res.data);
        var lstTab = [...tabs];
        var check = lstTab.find((x) => x.key == "2");
        if (!check) {
          lstTab.push({
            key: "2",
            label: "Chi tiết",
          });
          setTabs(lstTab);
        }

        if (res.data.paid > 0 && res.data.status != "resting") {
          api.get("/ClientClass/GetListLesson?classID=" + id).then((res) => {
            setListLessonPro(res.data);
          });
        }
      }
    });
    api.get("/ClientClass/GetListAttendanceByClass?classID=" + id).then((res) => {
      setListAttendance(res.data);
    });
    api.get("/ClientClass/GetListRelate?classID=" + id).then((res) => {
      setListRelate(res.data);
    });
  };

  const registerClass = () => {
    if (!dataLogin.isLogin) {
      message.error("Bạn cần đăng nhập để thực hiện thao tác này");
      return;
    }
    api
      .get("/Class/UserRegisterClass?classID=" + data.id)
      .then((res) => {
        message.success("Đăng ký thành công");
        getData(data.id);
      })
      .catch((err) => {
        message.error("Đăng ký thất bại");
      });
  };

  const attendance = (value, id) => {
    api
      .get(`Attendance/UserAttendance?lessonID=${id}&value=${value}`)
      .then((res) => {
        message.success("Điểm danh thành công");
        getData(data.id);
      })
      .catch((err) => {
        message.error("Điểm danh thất bại");
      });
  };

  return (
    <div>
      <div style={{ background: `linear-gradient(90deg, #23a2a2, ${mainColor})` }}>
        <div className="container" style={{ color: "white" }}>
          <Row gutter={[20, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div style={{ width: "100%", padding: "20px 0" }}>
                {dayjs() < dayjs(data.start_date) ? <Tag color="#2db7f5">Chưa diễn ra</Tag> : <></>}
                {dayjs() > dayjs(data.finish_date) ? <Tag color="#f50">Đã kết thúc</Tag> : <></>}
                {dayjs() >= dayjs(data.start_date) && dayjs() <= dayjs(data.finish_date) ? <Tag color="#87d068">Đang diễn ra</Tag> : <></>}
                <div style={{ marginBottom: 10, fontSize: 40, fontWeight: "bold" }}>{data.name ? data.name.toUpperCase() : data.name}</div>
                <div style={{ fontSize: 20, fontWeight: 500, marginBottom: 20 }}>{data.description}</div>
                <Row gutter={20}>
                  <Col span={12} style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>
                    <div style={{ display: "flex" }}>
                      <TagOutlined style={{ marginRight: 5, fontSize: 40 }} />

                      <div>
                        <div style={{ color: "#c9c9f7" }}>Mục tiêu khóa học</div>
                        <div style={{ fontWeight: "bold" }}>{target.name}</div>
                      </div>
                    </div>
                  </Col>

                  <Col span={12} style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>
                    <div style={{ display: "flex" }}>
                      <DollarOutlined style={{ marginRight: 5, fontSize: 40 }} />

                      <div>
                        <div style={{ color: "#c9c9f7" }}>Học phí</div>
                        <div style={{ fontWeight: "bold" }}>{formatCurrency(data.tuition)}/tháng</div>
                      </div>
                    </div>
                  </Col>

                  <Col span={12} style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>
                    <div style={{ display: "flex" }}>
                      <CalendarOutlined style={{ marginRight: 5, fontSize: 40 }} />

                      <div>
                        <div style={{ color: "#c9c9f7" }}>Thời gian khóa học</div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div style={{ marginRight: 10 }}>{formatShortDate(data.start_date)}</div>
                          <div style={{ marginRight: 10 }}>-</div>
                          <div>{formatShortDate(data.finish_date)}</div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col span={12} style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>
                    <div style={{ display: "flex" }}>
                      <LaptopOutlined style={{ marginRight: 5, fontSize: 40 }} />

                      <div>
                        <div style={{ color: "#c9c9f7" }}>Hình thức</div>
                        <div style={{ fontWeight: "bold" }}>Học online qua Ms Teams</div>
                      </div>
                    </div>
                  </Col>

                  <Col span={12} style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>
                    <div style={{ display: "flex" }}>
                      <UserOutlined style={{ marginRight: 5, fontSize: 40 }} />

                      <div>
                        <div style={{ color: "#c9c9f7" }}>Giảng viên</div>
                        <div style={{ fontWeight: "bold" }}>{listTeacher.map((x) => x.name).join(", ")}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {!userLinkClass && dataLogin.userLogin.role_id == 4 ? (
                  <div style={{ display: "flex", marginTop: 20 }}>
                    <Button
                      size="large"
                      style={{ backgroundColor: "#f59e0b", color: "white", margin: "auto", fontWeight: "700", border: "#f59e0b" }}
                      onClick={() => {
                        registerClass();
                      }}
                    >
                      Đăng ký khóa học
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Col>
            <Col xs={0} sm={0} md={12} lg={12} xl={12}>
              <div style={{ padding: 20, width: "100%", height: "100%", boxSizing: "border-box", display: "flex" }}>
                <img src={data.image} style={{ aspectRatio: 3 / 2, borderRadius: 20, margin: "auto", width: "100%" }} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid #d4d4d4" }}>
        <div className="container">
          <div>
            <Tabs
              size="large"
              activeKey={tabKey}
              items={tabs}
              onChange={(e) => {
                var key = "1";
                if (e == "2") key = e;
                setTabKey(key);
                if (e == "3") {
                  navigate("#teacher");
                  return;
                }
                if (e == "4") {
                  if (data.demo_video) window.open(data.demo_video, "_blank");
                  return;
                }
              }}
            ></Tabs>
          </div>
        </div>
      </div>
      <div>
        {tabKey == "1" ? (
          <div className="container">
            <div style={{ lineHeight: 1.5 }}>
              <div style={{ fontSize: 20, fontWeight: "bold", marginTop: 30 }}>GIỚI THIỆU KHÓA HỌC</div>
              <div className="class-content" dangerouslySetInnerHTML={{ __html: data.content }} style={{ marginLeft: 20 }}></div>
              <div style={{ fontSize: 20, fontWeight: "bold", padding: "20px 0" }}>CHI TIẾT KHÓA HỌC</div>
              <Row gutter={[20, 10]} style={{ marginLeft: 20 }}>
                {listLesson.map((x, index) => {
                  return (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <div style={{ fontWeight: "bold", fontSize: 20 }}>{`Buổi ${index + 1}: ${x.topic}`}</div>
                      <div className="lesson-content" dangerouslySetInnerHTML={{ __html: x.content }} style={{ fontSize: 15 }}></div>
                    </Col>
                  );
                })}
              </Row>
              <div style={{ fontSize: 20, fontWeight: "bold", marginTop: 20 }} id="teacher">
                GIẢNG VIÊN
              </div>
              <Row gutter={20} style={{ marginTop: 10 }}>
                {listTeacher.map((x) => {
                  return (
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                      <Card>
                        <Row gutter={20}>
                          <Col span={10}>
                            <img
                              className="image-hover"
                              src={x.avatar}
                              style={{ width: "100%", borderRadius: "50%" }}
                              onClick={() => {
                                navigate(`/teacher/${x.user_name}`);
                              }}
                            />
                          </Col>
                          <Col span={14}>
                            <div style={{ fontSize: 20, fontWeight: "bold" }}>{x.name}</div>
                            <hr style={{ width: "100%", margin: "10px 0" }} />
                            <div style={{ fontSize: 16 }}>{x.description}</div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        ) : (
          <></>
        )}
        {tabKey == "2" ? (
          <div className="container">
            <div style={{ marginTop: 20 }}>
              <Row gutter={[20, 20]}>
                {(userLinkClass && userLinkClass.paid > 0 && userLinkClass.status != "resting" ? listLessonPro : listLesson).map((x, index) => {
                  var now = dayjs();
                  var start_time = dayjs(x.start_time);
                  var end_time = dayjs(x.end_time);
                  var check_now = now.date() == start_time.date() && now.month() == start_time.month() && now.year() == start_time.year();
                  var check_past = now > end_time;
                  var check_future = now <= end_time;
                  var att = listAttendace.find((a) => a.lesson_id == x.id);
                  return (
                    <Col span={12}>
                      <Card style={check_now ? { backgroundColor: "orange", height: "100%" } : { height: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ marginRight: 20 }}>
                            <div style={{ fontWeight: "bold", color: mainColor, fontSize: 20 }}>{`Buổi ${index + 1}: ${x.topic}`}</div>
                            {dayjs() < dayjs(x.start_time) ? <Tag color="#2db7f5">Chưa diễn ra</Tag> : <></>}
                            {dayjs() > dayjs(x.end_time) ? <Tag color="#f50">Đã kết thúc</Tag> : <></>}
                            {dayjs() >= dayjs(x.start_time) && dayjs() <= dayjs(x.end_time) ? <Tag color="#87d068">Đang diễn ra</Tag> : <></>}
                            <div>
                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: 10, fontWeight: "bold" }}>Thời gian:</div>
                                <div>{` ${formatLongDate(x.start_time)}${"-"}${formatLongDate(x.end_time)}`}</div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: 10, fontWeight: "bold" }}>Giảng viên:</div>
                                <div>{`${listTeacher.find((y) => y.user_name == x.teacher_id)?.name}`}</div>
                              </div>
                              {x.video ? (
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginRight: 10, fontWeight: "bold" }}>
                                    <a href={x.video} target="_blank">
                                      Video bài giảng
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {userLinkClass && userLinkClass.paid == 0 ? (
                                <div style={{ display: "flex" }}>
                                  <div style={{ color: "red", fontSize: 20 }}>*</div>
                                  <div style={{ fontStyle: "italic", fontSize: 17 }}>Vui lòng liên hệ Sciphy đóng học phí để xem thêm thông tin khóa học!</div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          {dataLogin.userLogin?.role_id == 4 && userLinkClass.paid > 0 && userLinkClass.status != "resting" ? (
                            <div>
                              <div style={{ marginBottom: 10 }}>
                                <Tooltip placement="right" title={"Xem tài liệu"}>
                                  <Button type="primary" href={x.document_url} download target="_blank">
                                    <DownloadOutlined />
                                  </Button>
                                </Tooltip>
                              </div>
                              {!att ? (
                                <>
                                  {!check_past ? (
                                    <div style={{ marginBottom: 10 }}>
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              colorPrimary: `green`,
                                              colorPrimaryHover: `#64b364`,
                                              colorPrimaryActive: `green`,
                                              lineWidth: 0,
                                            },
                                          },
                                        }}
                                      >
                                        <Tooltip placement="right" title={"Điểm danh"}>
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              attendance("live", x.id);
                                            }}
                                          >
                                            <UsergroupAddOutlined />
                                          </Button>
                                        </Tooltip>
                                      </ConfigProvider>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {!check_future ? (
                                    <div>
                                      <ConfigProvider
                                        theme={{
                                          components: {
                                            Button: {
                                              colorPrimary: `#d31c07`,
                                              colorPrimaryHover: `#f16353`,
                                              colorPrimaryActive: `#d31c07`,
                                              lineWidth: 0,
                                            },
                                          },
                                        }}
                                      >
                                        <Tooltip placement="right" title={"Đã xem lại video"}>
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              attendance("review", x.id);
                                            }}
                                          >
                                            <VideoCameraOutlined />
                                          </Button>
                                        </Tooltip>
                                      </ConfigProvider>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 40,
              fontWeight: "bold",
              fontFamily: "CustomFont",
              color: mainColor,
              padding: "50px 0",
            }}
          >
            <div>Khóa học liên quan</div>
            <div
              className="text-hover"
              style={{ fontSize: 25 }}
              onClick={() => {
                navigate("/course");
              }}
            >
              Xem thêm
            </div>
          </div>
          <div>
            <ListItem type="class" list={listRelate}></ListItem>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClassDetail;
