import React from "react";
import ClassSide from "./class_slide";
import Achievement from "client_component/home/achievement";
import api from "api";
import ListItem from "./list-item";
import { mainColor } from "config";
import ClientFaq from "./faq";
import { useNavigate } from "react-router-dom";

const Home = (props) => {
  const [listBlog, setListBlog] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect((x) => {
    var dataPost = {
      Status: "publish",
      StartRow: 0,
      Length: 8,
      SortCol: "",
      SortType: "",
    };
    api.post("/ClientBlog/GetList", dataPost).then((res) => {
      setListBlog(res.data.data);
    });
  }, []);
  return (
    <>
      <ClassSide />
      <Achievement />
      <div>
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 40,
              fontWeight: "bold",
              fontFamily: "CustomFont",
              color: mainColor,
              padding: "50px 0",
            }}
          >
            <div>Bài viết</div>
            <div
              className="text-hover"
              style={{ fontSize: 25 }}
              onClick={() => {
                navigate("/blog");
              }}
            >
              Xem thêm
            </div>
          </div>
          <div>
            <ListItem type="blog" list={listBlog}></ListItem>
          </div>
        </div>
      </div>
      <ClientFaq></ClientFaq>
    </>
  );
};
export default Home;
