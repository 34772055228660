import React from "react";
import { mainColor } from "config";

const ButtonSlide = (props) => {
  const [bgColor, setBgColor] = React.useState("white");

  const onClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div style={{ display: "flex", position: "absolute", top: 0, bottom: 0, zIndex: 100, ...props.position }}>
      <div
        type="primary"
        style={{ display: "flex", backgroundColor: bgColor, height: 100, width: 50, borderRadius: 20, margin: "auto" }}
        className="button-slide"
        onMouseEnter={() => setBgColor(mainColor)}
        onMouseLeave={() => {
          setBgColor("white");
        }}
        onClick={onClick}
      >
        {props.children}
      </div>
    </div>
  );
};
export default ButtonSlide;
