import * as React from "react";
import api from "api";
import { Row, Button, Space, Card, Select, Col, Input, Popconfirm, message, DatePicker } from "antd";
import { EditOutlined, PlusOutlined, SearchOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { formatShortDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import dayjs from "dayjs";
import UserPopup from "component/user/user_popup";
import { imageURL } from "config/index";
import PopupJoinClass from "component/student/popup_join_class";

const { RangePicker } = DatePicker;

const Student = (props) => {
  const [lstclass, setlstclass] = React.useState([]);
  const tblstudent = React.useRef();
  const userPopup = React.useRef();
  const [dataGroup, setDataGroup] = React.useState([]);
  const [filter, setFilter] = React.useState({
    StartDate: null,
    EndDate: null,
  });
  const popupJoinClass = React.useRef();

  React.useEffect(() => {
    getListClass();
  }, []);

  React.useEffect(() => {
    getlststudent();
  }, [filter]);

  const getlststudent = () => {
    tblstudent.current.reload();
  };

  const getListClass = () => {
    api.get("Student/GetListClass").then((res) => {
      setlstclass(res.data);
    });
  };
  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const onGetDataFinish = (res) => {
    setDataGroup(res.group);
  };

  const deleteUser = (user) => {
    api.get("User/DeleteUser?username=" + user).then((res) => {
      message.success(res.message);
      getlststudent();
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    // {
    //   title: "Avatar",
    //   dataIndex: "avatar",
    //   sorter: true,
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         <img style={{ height: "100px" }} src={imageURL + record.avatar} />
    //       </>
    //     );
    //   },
    // },
    {
      title: "Tên học sinh",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trường",
      dataIndex: "school",
      key: "school",
    },
    {
      title: "Lớp",
      dataIndex: "class",
      key: "class",
    },

    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Facebook",
      dataIndex: "fb_link",
      key: "fb_link",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Lớp đang học",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              userPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm học sinh
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                userPopup.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>

            <Button
              type="primary"
              onClick={() => {
                popupJoinClass.current.open(record.user_name);
              }}
            >
              <UserAddOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa học sinh này không?"
              placement="topRight"
              onConfirm={() => {
                deleteUser(record.user_name);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái</div>
              <Select
                style={{ width: "100%" }}
                value={filter.Status}
                options={[
                  {
                    value: "cooperating",
                    label: "Đang học",
                  },
                  {
                    value: "resting",
                    label: "Đã nghỉ",
                  },
                ]}
                onChange={(e) => {
                  paramChange(e, "Status");
                }}
                allowClear
              ></Select>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "info");
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                getlststudent();
              }}
            >
              <SearchOutlined /> Tìm kiếm
            </Button>
          </Space>
        </div>
      </Card>

      <Card>
        <AntTable
          columns={col}
          ajax="User/Listuser"
          customSearch={{ ...filter, Type: "student" }}
          ref={tblstudent}
          onGetDataFinish={onGetDataFinish}
        ></AntTable>

        <UserPopup ref={userPopup} reload={getlststudent} type={"student"}></UserPopup>
        <PopupJoinClass ref={popupJoinClass} reload={getlststudent}></PopupJoinClass>
      </Card>
    </>
  );
};

export default Student;
