import React, { Component } from "react";
import APIHelper from "api";
import { Table, Modal, Checkbox, Button, Input, Slider, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import "./index.css";
const InputGroup = Input.Group;
class AntTable extends Component {
  constructor(props) {
    super(props);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    var oldColumns = getOldSetting(this.props.tableName, props.defaultCol);
    this.state = {
      data: [],
      showSetting: false,
      oldColumns: oldColumns,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,

        showTotal: (total, range) => {
          return (
            <div>
              {`${range[0]}-${range[1]} of ${total} ${total > 1 ? "items" : "item"}`}
              {this.props.tableName && (
                <span style={{ padding: "10px", cursor: "pointer" }}>
                  <span onClick={this.showSetting}>
                    <FontAwesomeIcon icon={faGear} />
                  </span>
                </span>
              )}
            </div>
          );
        },
        onShowSizeChange: this.onShowSizeChange,
        pageSizeOptions: ["10", "20", "30", "40", "10000"],
      },
      sorter: {
        field: "",
        order: "",
      },
      loading: false,
      width: 0,
    };
    this.table = React.createRef();
    this.container = React.createRef();
  }

  onShowSizeChange(current, pageSize) {
    const pager = { ...this.state.pagination };
    pager.pageSize = pageSize;
    this.setState({
      pagination: pager,
    });
  }

  exportExcel(fileName, header, proccessData) {
    const { ajax, customSearch } = this.props;
    const { pagination, sorter } = this.state;
    const pager = { ...this.state.pagination };
    var field = sorter.field;
    var order = sorter.order;
    var pageSize = pager.pageSize;
    var page = pager.current;
    var StartRow = (page - 1) * pageSize;
    if (StartRow < 0) StartRow = 0;
    var sort = {};
    if (field != "" && order != "") sort[field] = order;

    var dataPost = {
      ...customSearch,
      pagging: {
        skip: StartRow,
        limit: pageSize,
        sort,
      },
    };
    this.setState({ loading: true });
    APIHelper.post(ajax, dataPost).then((data) => {
      var colExcel = [];

      var columns = [...this.getColumns()];
      columns.forEach((d) => {
        if (d.children) {
          d.children.forEach((c) => {
            if (c.dataIndex) {
              var cc = {};
              cc.title = d.title + " " + c.title;
              cc.dataIndex = c.dataIndex;
              colExcel.push(cc);
            }
          });
        } else {
          if (d.dataIndex) {
            colExcel.push(d);
          }
        }
      });

      if (proccessData) {
        data.data = data.data.map((item) => {
          return proccessData(item);
        });
      }

      var lst = data.data.map((item) => {
        var o = {};
        colExcel.forEach((c) => {
          o[c.title] = item[c.dataIndex];
        });
        return o;
      });

      const ws = XLSX.utils.json_to_sheet(lst);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dt = new Blob([excelBuffer], { type: ".xlsx" });
      FileSaver.saveAs(dt, fileName + ".xlsx");

      this.setState({
        loading: false,
      });
    });
  }

  componentDidMount() {
    if (!(this.props.autoLoad == false)) {
      this.reload();
    }

    this.setState({ width: this.container.current.offsetWidth });
  }
  getCurrentPagination = () => {
    const { pagination, sorter } = this.state;
    const pager = { ...this.state.pagination };
    var field = sorter.field;
    var order = sorter.order;
    var pageSize = pager.pageSize;
    var page = pager.current;
    var StartRow = (page - 1) * pageSize;
    if (StartRow < 0) StartRow = 0;
    var sort = {};
    if (field != "" && order != "") sort[field] = order;

    var pagging = {
      skip: StartRow,
      limit: pageSize,
      sort,
    };
    return pagging;
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      sorter: sorter,
    });
    this.loadTable(pagination.current, pagination.pageSize, sorter.field ? sorter.field : "", sorter.order ? sorter.order : "");
  };
  reload = (spin, keepCurrentPage = true) => {
    var page = 1;
    const { pagination, sorter } = this.state;
    if (keepCurrentPage) {
      page = pagination.current;
    }

    this.loadTable(page, pagination.pageSize, sorter.field, sorter.order, spin);
  };
  loadTable = (page, pageSize, field, order, spin) => {
    const { ajax, customSearch } = this.props;
    var StartRow = (page - 1) * pageSize;
    if (StartRow < 0) StartRow = 0;
    if (!spin) this.setState({ loading: true });
    if (order == "ascend" || order == "asc") {
      order = 1;
    }

    if (order == "descend" || order == "desc") {
      order = -1;
    }
    var sort = {};
    if (field != "" && order != "") sort[field] = order;
    var dataPost = {
      ...customSearch,

      StartRow: StartRow,
      Length: pageSize,
      SortCol: field,
      SortType: order,
    };
    APIHelper.post(ajax, dataPost).then(
      (resp) => {
        var res = resp.data;
        const pagination = { ...this.state.pagination };
        pagination.total = res.total;
        pagination.current = page;

        if (res.total < (pagination.current - 1) * pagination.pageSize) {
          pagination.current = 1;
          pagination.total = 0;
          this.setState(
            {
              loading: false,
              pagination,
              data: null,
            },
            () => {
              this.reload();
            }
          );
          return;
        }

        if (this.props.onGetDataFinish) {
          this.props.onGetDataFinish(res);
        }

        this.setState({
          loading: false,
          data: res.data,
          pagination,
        });
      },

      (error) => {
        const pagination = { ...this.state.pagination };
        pagination.total = 0;
        pagination.current = 0;
        this.setState({
          loading: false,
          data: [],
          pagination,
        });

        if (this.props.onGetDataFinish) {
          this.props.onGetDataFinish({ error: error });
        }
      }
    );
  };

  hideSetting = () => {
    this.setState({ showSetting: false });
  };

  showSetting = () => {
    this.setState({ showSetting: true });
  };

  onColumnChange = () => {};

  saveSetting = () => {
    this.refs.ColSetting.saveSetting();
    this.settingChange();
  };

  resetDefaulf = () => {
    localStorage.removeItem("settingTB" + this.props.tableName);
    this.settingChange();
  };

  settingChange() {
    this.props.settingChange();
  }

  getColumns = () => {
    if (this.state.oldColumns.length == 0) {
      var lst = this.props.columns;
      var lstNew = lst.map(function (item, index) {
        item = { ...item };
        item["width"] = null;
        return item;
      });
      return lstNew;
    } else {
      var lst = [];
      this.state.oldColumns.forEach((element) => {
        if (element.show) {
          var col = this.props.columns.find((item) => {
            return item.dataIndex == element.dataIndex;
          });
          if (col) {
            if (element.width) col.width = element.width;
            lst.push({ ...col });
          }
        }
      });
      this.props.columns.forEach((element) => {
        var isExist = this.state.oldColumns.find((item) => {
          return item.dataIndex == element.dataIndex;
        });
        if (!isExist) {
          lst.push(element);
        }
      });
      //cột con
      lst.forEach((item) => {
        if (item.children) {
          var colSetting = this.state.oldColumns.find((e) => {
            return e.dataIndex == item.dataIndex;
          });
          if (colSetting && colSetting.children) {
            var lstTemp = [...item.children];
            item.children = [];
            lstTemp.forEach((child) => {
              var c = colSetting.children.find((e) => {
                return e.dataIndex == child.dataIndex;
              });
              if (!(c && c.show == false)) {
                if (c.width) child.width = c.width;
                item.children.push(child);
              }
            });
          }
        }
      });
      return lst;
    }
  };

  settingChange = () => {
    var oldColumns = getOldSetting(this.props.tableName, this.props.defaultCol);
    this.setState({ oldColumns: oldColumns, showSetting: false });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    var loading = this.state.loading;
    var columns = this.getColumns();
    var width = 0;

    columns.forEach((col) => {
      if (col.children) {
        col.children.forEach((childCol) => {
          width += childCol.width ?? 100;
        });
      } else {
        width += col.width ?? 100;
      }
    });
    var scroll = {};

    if (this.props.scroll) {
      scroll = this.props.scroll;
    }
    if (this.state.width > 0) {
      if (width > this.state.width + 100) {
        scroll.x = width;
      } else {
        scroll.x = false;
      }
    }
    return (
      <div ref={this.container}>
        <Table
          {...this.props}
          scroll={scroll}
          columns={columns}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          loading={loading}
          onChange={this.handleTableChange}
          forwardRef={(ref) => {
            this.table = ref;
          }}
        />
        <Modal
          maskClosable={false}
          title="Tùy chỉnh bảng"
          visible={this.state.showSetting}
          footer={[
            <Button onClick={this.resetDefaulf}>Cài lại</Button>,
            <Button onClick={this.hideSetting}>Đóng</Button>,
            <Button type="primary" onClick={this.saveSetting}>
              Lưu
            </Button>,
          ]}
          onCancel={this.hideSetting}
        >
          <ColSetting
            ref="ColSetting"
            columns={this.props.columns}
            defaultCol={this.props.defaultCol}
            onColumnChange={this.onColumnChange}
            tableName={this.props.tableName}
            settingChange={this.settingChange}
          />
        </Modal>
      </div>
    );
  }
}

const SortableItem = SortableElement(({ value, onChange }) => {
  var lstChild = null;
  if (value.children) {
    lstChild = value.children.map((child, i) => {
      return (
        <li key={value.dataIndex + "_" + i} style={{ zIndex: 2099, height: "35px" }}>
          <div className="pull-left" style={{ marginTop: "5px" }}>
            {child.title}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              className="pull-right"
              style={{ marginTop: "5px" }}
              checked={child.show}
              onChange={(e) => {
                onChange(child.dataIndex, e.target.checked, "show", value.dataIndex);
              }}
            ></Checkbox>

            <InputGroup compact style={{ width: "150px" }}>
              <Button
                size="small"
                icon={<FontAwesomeIcon icon={faMinus} style={{ marginRight: 5 }} />}
                style={{ width: "30px" }}
                onClick={(e) => {
                  onChange(child.dataIndex, child.width ? parseInt(child.width) - 5 : 99, "width", value.dataIndex);
                }}
              ></Button>
              <Input
                size="small"
                style={{ width: "60px" }}
                value={child.width ? child.width : null}
                onChange={(e) => {
                  onChange(child.dataIndex, parseInt(e.target.value), "width", value.dataIndex);
                }}
              />
              <Button
                size="small"
                icon={<FontAwesomeIcon icon={faPlus} />}
                style={{ width: "30px" }}
                onClick={(e) => {
                  onChange(child.dataIndex, child.width ? parseInt(child.width) + 5 : 101, "width", value.dataIndex);
                }}
              ></Button>
            </InputGroup>
          </div>
        </li>
      );
    });
  }
  return (
    <li
      style={{
        border: "1px solid",
        listStyleType: "none",
        cursor: "pointer",
        padding: "10px",
        zIndex: 2099,
      }}
    >
      <div className="pull-left" style={{ paddingTop: "5px" }}>
        {value.title}
      </div>
      <div style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
        <div style={{ marginRight: 10 }}>
          <Checkbox
            className="pull-right"
            style={{ paddingTop: "5px" }}
            checked={value.show}
            onChange={(e) => {
              onChange(value.dataIndex, e.target.checked, "show");
            }}
          ></Checkbox>
        </div>
        {!value.children && (
          <InputGroup compact className="pull-right" style={{ width: "150px" }}>
            <Button
              size="small"
              icon={<FontAwesomeIcon icon={faMinus} style={{ marginRight: 5 }} />}
              onClick={(e) => {
                onChange(value.dataIndex, value.width ? parseInt(value.width) - 5 : 99, "width");
              }}
            ></Button>
            <Input
              size="small"
              style={{ width: "60px" }}
              value={value.width ? value.width : null}
              onChange={(e) => {
                onChange(value.dataIndex, parseInt(e.target.value), "width");
              }}
            />
            <Button
              size="small"
              icon={<FontAwesomeIcon icon={faPlus} />}
              style={{ width: "30px" }}
              onClick={(e) => {
                onChange(value.dataIndex, value.width ? parseInt(value.width) + 5 : 101, "width");
              }}
            ></Button>
          </InputGroup>
        )}
      </div>
      <div></div>
      <div className="clearfix"></div>
      {lstChild != null && <ul style={{ paddingTop: "5px" }}>{lstChild}</ul>}
      {/* <Slider value={value.width} disabled={false} onChange={e => { onChange(value.dataIndex, e) }} /> */}
    </li>
  );
});

const SortableList = SortableContainer(({ items, onChange }) => {
  return (
    <ul style={{ padding: "0px" }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} onChange={onChange} />
      ))}
    </ul>
  );
});

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

const getOldSetting = (name, defaultCol) => {
  var setting = localStorage.getItem("settingTB" + name);
  if (setting == null) {
    if (defaultCol) {
      return defaultCol;
    }
    return [];
  }
  return JSON.parse(setting);
};

class ColSetting extends Component {
  constructor(props) {
    super(props);
    var lst = [];
    var name = this.props.tableName;
    if (name == null) {
      name = window.location.pathname;
    }

    var oldSetting = getOldSetting(name, this.props.defaultCol);
    if (oldSetting.length == 0) {
      props.columns.forEach((element) => {
        if (element.dataIndex) {
          element.show = true;
          element.width = null;
          lst.push(element);
        }
      });
    } else {
      oldSetting.forEach((element) => {
        var col = props.columns.find((item) => {
          return item.dataIndex == element.dataIndex;
        });
        if (col) {
          col.show = element.show;
          col.width = element.width;
          lst.push(col);
        }
      });
      props.columns.forEach((element) => {
        if (element.dataIndex) {
          var col = oldSetting.find((item) => {
            return item.dataIndex == element.dataIndex;
          });
          if (!col) {
            element.show = true;
            lst.push(element);
          }
        }
      });
    }

    lst = lst.map((item) => {
      var col = props.columns.find((col) => {
        return item.dataIndex == col.dataIndex;
      });

      if (col.children) {
        var oldCol = oldSetting.find((old) => {
          return item.dataIndex == old.dataIndex;
        });
        var lstChild = col.children.map((child) => {
          child.show = true;
          if (oldCol && oldCol.children) {
            var c = oldCol.children.find((e) => {
              return e.dataIndex == child.dataIndex;
            });
            if (c) {
              child.show = c.show;
              child.width = c.width;
            }
          }

          return child;
        });

        item.children = lstChild;
      }
      return item;
    });

    this.state = {
      items: lst,
    };
  }
  componentWillReceiveProps(nextprop) {
    this.ColSetting(nextprop);
  }
  ColSetting = (props) => {
    var lst = [];
    var name = props.tableName;
    if (name == null) {
      name = window.location.pathname;
    }

    var oldSetting = getOldSetting(name, this.props.defaultCol);
    if (oldSetting.length == 0) {
      props.columns.forEach((element) => {
        if (element.dataIndex) {
          element.show = true;
          element.width = null;
          lst.push(element);
        }
      });
    } else {
      oldSetting.forEach((element) => {
        var col = props.columns.find((item) => {
          return item.dataIndex == element.dataIndex;
        });
        if (col) {
          col.show = element.show;
          col.width = element.width;
          lst.push(col);
        }
      });
      props.columns.forEach((element) => {
        if (element.dataIndex) {
          var col = oldSetting.find((item) => {
            return item.dataIndex == element.dataIndex;
          });
          if (!col) {
            element.show = true;
            lst.push(element);
          }
        }
      });
    }

    lst = lst.map((item) => {
      var col = props.columns.find((col) => {
        return item.dataIndex == col.dataIndex;
      });

      if (col.children) {
        var oldCol = oldSetting.find((old) => {
          return item.dataIndex == old.dataIndex;
        });
        var lstChild = col.children.map((child) => {
          child.show = true;
          child.width = null;
          if (oldCol && oldCol.children) {
            var c = oldCol.children.find((e) => {
              return e.dataIndex == child.dataIndex;
            });
            if (c) {
              child.show = c.show;
              child.width = c.width;
            }
          }

          return child;
        });

        item.children = lstChild;
      }
      return item;
    });

    // this.setState({ items: lst })
    this.setState(
      { items: lst },
      function () {
        this.columnChange();
      }.bind(this)
    );
  };

  columnChange() {
    if (this.props.onColumnChange) {
      this.props.onColumnChange([...this.state.items]);
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
      }),
      function () {
        this.columnChange();
      }.bind(this)
    );
  };
  onChange(key, value, type, parent) {
    var stateOld = [...this.state.items];
    var oldItem = null;
    if (parent) {
      var pr = stateOld.find(function (item) {
        return item.dataIndex == parent;
      });

      oldItem = pr.children.find(function (item) {
        if (!item) return false;
        return item.dataIndex == key;
      });
    } else {
      oldItem = stateOld.find(function (item) {
        return item.dataIndex == key;
      });
    }
    oldItem[type] = value;
    this.setState(
      { items: stateOld },
      function () {
        this.columnChange();
      }.bind(this)
    );
  }

  saveSetting() {
    var lst = this.state.items.map((item) => {
      var data = {
        dataIndex: item.dataIndex,
        show: item.show,
        width: item.width,
      };
      if (item.children) {
        data.children = item.children.map((child) => {
          return {
            dataIndex: child.dataIndex,
            show: child.show,
            width: child.width,
          };
        });
      }
      return data;
    });

    localStorage.setItem("settingTB" + this.props.tableName, JSON.stringify(lst));
  }

  render() {
    return (
      <div style={{ backgroundColor: "white", width: "100%" }}>
        <SortableList items={this.state.items} onChange={this.onChange.bind(this)} onSortEnd={this.onSortEnd} />
      </div>
    );
  }
}

export default AntTable;
