import React from "react";
import { Image, Card } from "antd";

const MediaFBDisplay = (props) => {
  console.log(props.media);
  return (
    <Card style={{ height: 350 }}>
      {!props.media.source ? (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Image height={260} src={props.media.media?.image?.src} />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <video controls style={{ width: "100%", height: 260 }}>
            <source src={props.media.media?.source} />
          </video>
        </div>
      )}
      <div>Type: {props.media.type}</div>
    </Card>
  );
};
export default MediaFBDisplay;
