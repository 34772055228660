import * as React from "react";
import api from "api";
import { TimePicker, Input, Form, message, Modal, Select } from "antd";
import dayjs from "dayjs";

const { TextArea } = Input;

const PopupEditReceiptsExpensesType = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();

  const form = React.useRef();

  const saveReceiptsExpensesType = (values) => {
    api
      .post("/ReceiptsExpensesType/Update", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setTimeout(() => {
        if (id) {
          api.get("/ReceiptsExpensesType/GetByID?id=" + id).then((res) => {
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Loại thu chi"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={saveReceiptsExpensesType} layout="vertical">
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item label="Tên " name="name" rules={[{ required: true, message: "Nhập tên!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Loại" name="type">
          <Select
            options={[
              { value: "receipts", label: "Thu" },
              { value: "expenses", label: "Chi" },
            ]}
          ></Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default PopupEditReceiptsExpensesType;
