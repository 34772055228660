import * as React from "react";
import api from "api";
import { parseJwt } from "common/jwt/jwt_helper";
import {
  AppstoreOutlined,
  LogoutOutlined,
  BlockOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  ExperimentOutlined,
  ContainerOutlined,
  QuestionCircleOutlined,
  RocketOutlined,
  StarOutlined,
  MenuOutlined,
  BookOutlined,
  DollarOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Layout, Button, Menu, Avatar, Row, Dropdown, Spin } from "antd";
import { useNavigate, Outlet } from "react-router-dom";
import "./App.css";
import { imageURL, permission } from "config/index";
import ChangePassWord from "./component/user/changepass_popup";
import UserInfo from "./component/user/userinfo_popup";
import { registerLoading } from "component/common/loading";
import { mainColor } from "config";

const { Header, Footer, Sider, Content } = Layout;

const Admin = (props) => {
  const changepass = React.useRef();
  const userinfo = React.useRef();
  const [collapsed, setCollapsed] = React.useState(false);
  const [headerTitle, setHeaderTitle] = React.useState("");
  const [userlogin, setUserlogin] = React.useState("");
  const [loadingApp, setLoadingApp] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState(["/config"]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const navigate = useNavigate();
  registerLoading(setLoadingApp);
  const [avatar, setavatar] = React.useState("");

  const avatarMenu = [
    {
      icon: <UserAddOutlined />,
      label: "Thông tin tài khoản",
      key: "userinfo",
    },
    {
      icon: <BlockOutlined />,
      label: "Đổi mật khẩu",
      key: "changePassword",
    },
    {
      icon: <LogoutOutlined />,
      label: "Đăng xuất",
      key: "logout",
    },
  ];
  const handleAvatarMenu = (e) => {
    switch (e.key) {
      case "userinfo":
        userinfo.current.open(userlogin.user_name);
        break;
      case "changePassword":
        changepass.current.open(userlogin.user_name);
        break;
      case "logout":
        api.get("/Login/Logout").then((res) => {
          checkLogin();
        });
        break;
    }
  };
  const itemMenu = [
    {
      key: "/admin/student",
      icon: <UsergroupAddOutlined />,
      label: "Học sinh",
    },

    {
      key: "/admin/user",
      icon: <UserAddOutlined />,
      label: "Nhân viên",
    },

    {
      key: "/admin/target",
      icon: <StarOutlined />,
      label: "Mục tiêu",
    },
    {
      key: "/admin/course",
      icon: <ContainerOutlined />,
      label: "Khóa học",
    },
    {
      key: "/admin/document",
      icon: <FileDoneOutlined />,
      label: "Tài liệu",
    },
    {
      key: "/admin/blog-category",
      icon: <AppstoreOutlined />,
      label: "Danh mục bài viết",
    },
    {
      key: "/admin/blog",
      icon: <BookOutlined />,
      label: "Bài viết",
    },
    {
      key: "/admin/revoucher",
      icon: <DollarOutlined />,
      label: "Thu chi",
    },
    {
      key: "/admin/revoucher-type",
      icon: <MenuOutlined />,
      label: "Loại thu chi",
    },
    {
      key: "/admin/achievement",
      icon: <RocketOutlined />,
      label: "Thành tích",
    },
    {
      key: "/admin/mission",
      icon: <ExperimentOutlined />,
      label: "Mission",
    },
    {
      key: "/admin/faq",
      icon: <QuestionCircleOutlined />,
      label: "FAQ",
    },
  ];

  const getMenu = () => {
    var token = localStorage.getItem("token");
    if (token) {
      var roleConfig = permission.find((x) => x.role == parseJwt(token).role);

      if (roleConfig) {
        if (roleConfig.menu == "ALL") return itemMenu;
        const lstMenu = itemMenu.filter((x) => roleConfig.menu.includes(x.key));
        lstMenu.forEach((item) => {
          if (item.children) {
            item.children = item.children.filter((x) => roleConfig.menu.includes(x.key));
          }
        });

        return lstMenu;
      }
    }

    return itemMenu;
  };

  const footerStyle = {
    textAlign: "center",
    backgroundColor: mainColor,
    color: "#fff",
  };

  const onOpenKeyChange = (lstKey) => {
    setOpenKeys(lstKey);
  };

  const setHeader = function () {
    var labelHeader = undefined;
    itemMenu.every((item) => {
      if (item.children) labelHeader = item.children.find((x) => x.key == window.location.pathname);
      if (item.key == window.location.pathname) labelHeader = item;
      if (labelHeader) return false;
      return true;
    });
    if (labelHeader) setHeaderTitle(labelHeader.label);
    else setHeaderTitle("Điểm danh");
  };

  React.useEffect(() => {
    setHeader();
    checkLogin();
  }, []);

  const checkLogin = () => {
    api
      .get("user/AdminUser")
      .then((res) => {
        //setavatar(res.data.useravatar);
        setUserlogin(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        id="menuLeft"
        style={{
          backgroundColor: mainColor,
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 100,
        }}
      >
        <div style={{ padding: 10 }}>
          <div
            onClick={toggleCollapsed}
            style={{
              width: "100%",
              height: 44,
              backgroundImage: `url("/image/logo2.png")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundColor: mainColor,
              backgroundPosition: "center center",
              cursor: "pointer",
            }}
          ></div>
        </div>

        <Menu
          selectedKeys={window.location.pathname}
          // defaultOpenKeys={[window.location.pathname]}
          style={{ color: "#fff", backgroundColor: mainColor, fontWeight: 500 }}
          mode="inline"
          inlineCollapsed={collapsed}
          items={getMenu()}
          onSelect={(data) => {
            navigate(data.key);
            setHeader();
          }}
          openKeys={openKeys}
          onOpenChange={onOpenKeyChange}
        />
      </div>
      <div
        style={{
          backgroundColor: mainColor,
          height: "100vh",
          pointerEvents: "none",
          overflowY: "clip",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 64,
          }}
        ></div>
        <Menu mode="inline" inlineCollapsed={collapsed} items={itemMenu} openKeys={collapsed ? [] : openKeys} />
      </div>
      <Layout>
        <Header
          style={{
            backgroundColor: mainColor,
            paddingInline: "20px !important",
            position: "sticky",
            top: 0,
            zIndex: 999,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 300,
            }}
          />
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
            }}
          >
            <span style={{ fontSize: 38, fontWeight: "bold", color: "#ffffff" }}>{headerTitle}</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: 300,
            }}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: avatarMenu,
                onClick: handleAvatarMenu,
              }}
            >
              <div style={{ cursor: "pointer", marginLeft: 20 }}>
                <Avatar size={38} lt="avatar" />
                <span style={{ color: "#fff", marginLeft: 10 }}>{userlogin.name}</span>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content>
          <div style={{ padding: 10, borderRadius: 5 }}>
            <Outlet context={{ userlogin }} />
          </div>
        </Content>
      </Layout>
      <ChangePassWord ref={changepass}></ChangePassWord>
      <UserInfo ref={userinfo}></UserInfo>
      {loadingApp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999,
              backgroundColor: "#d3d1d1",
              opacity: 0.5,
            }}
          ></div>
          <div style={{ margin: "auto", zIndex: 10000 }}>
            <Spin size={"large"}></Spin>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
