import * as React from "react";
import api from "api";
import { Select, Input, Form, message, Modal, Table, Row, Col, InputNumber, Checkbox, Card, DatePicker } from "antd";
import { setAppLoading } from "component/common/loading";
import dayjs from "dayjs";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import UploadImagePicker from "component/picker/image_picker";
import Slug from "component/picker/slug_picker";
import { toNoUnicode } from "common/Text";
import { useNavigate } from "react-router-dom";
import { myDomain } from "config";
import HTMLEditorPicker from "component/picker/html_editor_picker";

const { TextArea } = Input;

const PopupEditComment = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [listCategory, setListCategory] = React.useState([]);
  const form = React.useRef();

  const navigate = useNavigate();

  const saveComment = (values, lst) => {
    api
      .post("/Blog/UpdateComment", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    props.reload();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(class_id, id) {
      setVisible(true);
      setTimeout(() => {
        if (id) {
          api.get("/Blog/GetCommentByID?id=" + id).then((res) => {
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({ blog_id: class_id });
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa lớp"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="50%"
    >
      <Row style={{ display: "flex" }} gutter={20}>
        <Col span={24}>
          <Card>
            <Form
              ref={form}
              onFinish={(values) => {
                saveComment(values);
              }}
              layout="vertical"
            >
              <Form.Item name="id" hidden></Form.Item>
              <Form.Item name="blog_id" hidden></Form.Item>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item label="Bình luận" name="comment">
                    <TextArea rows={8} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
});
export default PopupEditComment;
