import React from "react";
import AntTable from "component/common/AntTable";
import { Modal, Button, Card, Row, Col, Input, Space, Tag, message, Select } from "antd";
import { formatShortDate } from "common/Format2";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { imageURL } from "config";
import api from "api";

const PopupRelatedBlog = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [id, setID] = React.useState();
  const [filter, setFilter] = React.useState({});
  const [listSelected, setListSelected] = React.useState([]);

  const tblclass = React.useRef();

  const getLstBlog = () => {
    tblclass.current.reload();
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const saveRelatedBlog = () => {
    api.post("/Blog/AddBlogRelated", { RelatedIDs: listSelected, ID: id }).then((res) => {
      message.success("Thành công");
      props.reload();
      setVisible(false);
    });
  };

  const getLstBlogRelated = (id) => {
    api.get("/Blog/GetBlogRelated?blogID=" + id).then((res) => {
      setListSelected(res.data.map((x) => x.blog_related_id));
    });
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setID(id);
      getLstBlogRelated(id);
      setTimeout(() => {
        getLstBlog();
      }, 300);
    },
  }));

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={record.image} />
          </>
        );
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "draft") return <Tag>Nháp</Tag>;
        if (text == "publish") return <Tag color="#108ee9">Công khai</Tag>;
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      width="70%"
      open={visible}
      title={"Bài viết liên quan"}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        saveRelatedBlog();
      }}
      okText="Thêm"
      cancelText="Thoát"
    >
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Danh mục</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.CategoryIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "CategoryIDs");
                }}
                options={props.listCategory}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Nhân viên</div>
              <Select
                fieldNames={{ label: "name", value: "user_name" }}
                value={filter.UserIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "UserIDs");
                }}
                options={props.lstUser}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái</div>
              <Select
                value={filter.Status}
                onChange={(e) => {
                  paramChange(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                allowClear
                options={[
                  { value: "draft", label: "Bản nháp" },
                  { value: "public", label: "Công khai" },
                ]}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.status}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
                onPressEnter={() => {
                  getLstBlog();
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstBlog();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>

      <Card>
        <AntTable
          rowSelection={{
            type: "checkbox",
            onChange: (newSelectedRowKeys) => {
              setListSelected(newSelectedRowKeys);
            },
            hideSelectAll: true,
            selectedRowKeys: listSelected,
          }}
          columns={col}
          rowKey="id"
          ajax="Blog/GetList"
          customSearch={{ ...filter, DifferentID: id }}
          ref={tblclass}
        ></AntTable>
      </Card>
    </Modal>
  );
});
export default PopupRelatedBlog;
