import React from "react";
import { useParams } from "react-router-dom";
import { Table, message, Button, Space, Card, Popconfirm, Input, Row, Col, Tooltip } from "antd";
import dayjs from "dayjs";
import api from "api";
import { formatLongDate } from "common/Format2";
import { EditOutlined, DeleteOutlined, PlusOutlined, TeamOutlined } from "@ant-design/icons";
import PopupEditLesson from "./popup_edit_lesson";
import PopupAttendance from "./popup_attendance";

const Lesson = (props) => {
  var data = useParams();
  const [listLesson, setListLesson] = React.useState([]);
  const [listLessonFilter, setListLessonFilter] = React.useState([]);
  const [dataClass, setDataClass] = React.useState("");
  const popupEditLesson = React.useRef();
  const popupAttendance = React.useRef();

  React.useEffect(() => {
    api.get("/Class/GetBySlug?slug=" + data.slug).then((res) => {
      setDataClass(res.data);
    });
    getlistLesson();
  }, [props.reload]);

  const getlistLesson = () => {
    api.get("Lesson/GetListByClass?slug=" + data.slug).then((res) => {
      setListLesson(res.data);
      setListLessonFilter(res.data);
    });
  };

  const deleteLesson = (id) => {
    api.get("Lesson/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getlistLesson();
    });
  };

  const filterLesson = (value) => {
    if (value == null || value == "") {
      setListLessonFilter(listLesson);
    } else {
      setListLessonFilter(listLesson.filter((x) => x.topic?.includes(value)));
    }
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "start_time",
      key: "start_time",
      render: (text, record, index) => {
        return dayjs(text).format("DD-MM-YYYY HH:mm");
      },
    },
    {
      title: "Thời gian kết thúc",
      dataIndex: "end_time",
      key: "end_time",
      render: (text, record, index) => {
        return dayjs(text).format("DD-MM-YYYY HH:mm");
      },
    },
    {
      title: "Teacher",
      dataIndex: "teacher_id",
      key: "teacher_id",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
            <Button
              style={{ justifyContent: "center" }}
              type="primary"
              onClick={() => {
                popupEditLesson.current.open();
              }}
            >
              <PlusOutlined />
              Thêm
            </Button>
          </div>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Tooltip title={"Sửa"}>
              <Button
                type="primary"
                onClick={() => {
                  popupEditLesson.current.open(record.id);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={"Điểm danh"}>
              <Button
                type="primary"
                onClick={() => {
                  popupAttendance.current.open(record.id);
                }}
              >
                <TeamOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Popconfirm
                title="Bạn có muốn xóa quyền này không"
                placement="topRight"
                onConfirm={() => {
                  deleteLesson(record.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  filterLesson(e.target.value, "Info");
                }}
              />
            </div>
          </Col>
        </Row>
      </Card>
      <Card>
        <Table style={{ width: "100%" }} columns={col} dataSource={listLessonFilter} rowKey="id" />
        <PopupAttendance ref={popupAttendance}></PopupAttendance>
        <PopupEditLesson ref={popupEditLesson} reload={getlistLesson} slug={data.slug}></PopupEditLesson>
      </Card>
    </>
  );
};
export default Lesson;
