import React from "react";
import Slider from "react-slick";
import api from "api";
import { LeftOutlined, RightOutlined, TagOutlined, UserOutlined, CalendarOutlined, DollarOutlined, LaptopOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Row, Col, Space } from "antd";
import ButtonSlide from "./button-slide";
import { mainColor, myDomain } from "config";
import { formatShortDate, formatCurrency } from "common/Format2";
import { useNavigate } from "react-router-dom";
import "../style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClassSide = (props) => {
  const [listClass, setListClass] = React.useState([]);
  const slide = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    api.get("/ClientClass/GetListClass").then((res) => {
      setListClass(res.data);
    });
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    centerMode: true,
    fade: true,
    dots: true,
    prevArrow: (
      <ButtonSlide
        position={{ left: -15 }}
        onClick={() => {
          slide.current.slickPrev();
        }}
      >
        <LeftOutlined style={{ fontSize: 25, margin: "auto", color: "black" }} />
      </ButtonSlide>
    ),
    nextArrow: (
      <ButtonSlide
        position={{ right: -15 }}
        onClick={() => {
          slide.current.slickNext();
        }}
      >
        <RightOutlined style={{ fontSize: 25, margin: "auto", color: "black" }} />
      </ButtonSlide>
    ),
  };

  return (
    <div style={{ padding: "40px 0 40px 0" }}>
      <div className="container">
        <Slider {...settings} ref={slide}>
          {listClass.map((x) => {
            return (
              <div>
                <Row style={{ boxShadow: "0px 4px 8px 4px #dddddd", borderRadius: 20, overflow: "hidden", marginBottom: 20 }}>
                  <Col xs={0} sm={0} md={0} lg={12} xl={12} style={{ marginRight: -50, borderTopLeftRadius: 20, overflow: "hidden" }}>
                    <img
                      className="image-hover"
                      src={x.image}
                      style={{ width: "100%", height: "100%" }}
                      onClick={() => {
                        navigate(`/course/${x.slug}`);
                      }}
                    ></img>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={13}
                    xl={13}
                    style={{ width: "50%", padding: 20, borderRadius: 20, backgroundColor: "white", display: "flex" }}
                  >
                    <div style={{ marginTop: "auto", marginBottom: "auto", width: "100%" }}>
                      <div
                        className="text-hover"
                        style={{ fontSize: 40, fontFamily: "CustomFont", color: mainColor }}
                        onClick={() => {
                          navigate(`/course/${x.slug}`);
                        }}
                      >
                        {x.name}
                      </div>
                      <hr />
                      <p className="text-line-3" style={{ fontSize: "1rem", lineHeight: "150%", color: "rgb(102 102 102)", marginBottom: 20 }}>
                        {x.description}
                      </p>
                      <div style={{ display: "flex", marginBottom: 20, flexWrap: "wrap" }}>
                        <div style={{ display: "flex", marginRight: 30, marginBottom: 10 }}>
                          <div style={{ marginRight: 5, fontSize: 30, color: mainColor }}>
                            <TagOutlined />
                          </div>
                          <div>
                            <div style={{ fontSize: 13, color: "#aaa" }}>Mục tiêu khóa học</div>
                            <div>{x.target_name}</div>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginRight: 30, marginBottom: 10 }}>
                          <div style={{ marginRight: 5, fontSize: 30, color: mainColor }}>
                            <DollarOutlined />
                          </div>
                          <div>
                            <div style={{ fontSize: 13, color: "#aaa" }}>Học phí</div>
                            <div>{formatCurrency(x.tuition)}/tháng</div>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginRight: 30, marginBottom: 10 }}>
                          <div style={{ marginRight: 5, fontSize: 30, color: mainColor }}>
                            <CalendarOutlined />
                          </div>
                          <div>
                            <div style={{ fontSize: 13, color: "#aaa" }}>Thời gian khóa học</div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <div style={{ marginRight: 10 }}>{formatShortDate(x.start_date)}</div>
                              <div style={{ marginRight: 10 }}>-</div>
                              <div>{formatShortDate(x.finish_date)}</div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginRight: 30, marginBottom: 10 }}>
                          <div style={{ marginRight: 5, fontSize: 30, color: mainColor }}>
                            <LaptopOutlined />
                          </div>
                          <div>
                            <div style={{ fontSize: 13, color: "#aaa" }}>Hình thức</div>
                            <div>Học online qua Ms Teams</div>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginRight: 30, marginBottom: 10 }}>
                          <div style={{ marginRight: 5, fontSize: 30, color: mainColor }}>
                            <UserOutlined />
                          </div>
                          <div>
                            <div style={{ fontSize: 13, color: "#aaa" }}>Giảng viên</div>
                            <div>{x.teacher_name}</div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{
                            color: "pink",
                            fontFamily: "CustomFont",
                            backgroundColor: mainColor,

                            height: 50,
                            width: 250,
                            fontSize: 20,
                            display: "flex",
                          }}
                          onClick={() => {
                            navigate(`/course/${x.slug}`);
                          }}
                        >
                          <Space style={{ margin: "auto" }}>
                            <StarOutlined style={{ fontSize: 30, marginTop: 3 }} /> Xem khóa học
                          </Space>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default ClassSide;
