import * as React from "react";
import api from "api";
import { Popconfirm, Button, Table, Space, Card, message } from "antd";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import PopupEditReceiptsExpensesType from "./popup_edit_revoucher_type";

const ReceiptsExpensesType = (props) => {
  const [listReceiptsExpensesType, setListReceiptsExpensesType] = React.useState([]);
  const popupEditReceiptsExpensesType = React.useRef();

  React.useEffect(() => {
    getlistReceiptsExpensesType();
  }, [props.reload]);

  const getlistReceiptsExpensesType = () => {
    api.get("ReceiptsExpensesType/GetList").then((res) => {
      setListReceiptsExpensesType(res.data);
    });
  };

  const deleteReceiptsExpensesType = (id) => {
    api.get("ReceiptsExpensesType/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getlistReceiptsExpensesType();
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Loại",
      dataIndex: "type",
      key: "type",
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              popupEditReceiptsExpensesType.current.open();
            }}
          >
            <PlusOutlined />
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        if (record.id != 1 && record.id != 2 && record.id != 3)
          return (
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
              <Button
                type="primary"
                onClick={() => {
                  popupEditReceiptsExpensesType.current.open(record.id);
                }}
              >
                <EditOutlined />
              </Button>

              <Popconfirm
                title="Bạn có muốn xóa quyền này không"
                placement="topRight"
                onConfirm={() => {
                  deleteReceiptsExpensesType(record.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          );
      },
    },
  ];

  return (
    <Card>
      <Table style={{ width: "100%" }} columns={col} dataSource={listReceiptsExpensesType} rowKey="id" />

      <PopupEditReceiptsExpensesType ref={popupEditReceiptsExpensesType} reload={getlistReceiptsExpensesType}></PopupEditReceiptsExpensesType>
    </Card>
  );
};

export default ReceiptsExpensesType;
