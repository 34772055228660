import * as React from "react";
import api from "api";
import { Input, Form, message, Modal } from "antd";
import HTMLEditorPicker from "component/picker/html_editor_picker";

const { TextArea } = Input;

const PopupEditFaq = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();

  const form = React.useRef();

  const saveFaq = (values) => {
    api
      .post("/Faq/Update", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setTimeout(() => {
        if (id) {
          api.get("/Faq/GetByID?id=" + id).then((res) => {
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa Faq"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="50%"
    >
      <Form ref={form} onFinish={saveFaq} layout="vertical">
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item label="Câu hỏi" name="question" rules={[{ required: true, message: "Nhập câu hỏi!" }]}>
          <TextArea />
        </Form.Item>

        <Form.Item
          label="Câu trả lời"
          name="answer"
          rules={[
            { required: true, message: "Nhập câu trả lời!" },
            { pattern: /^(?!<p><br><\/p>$)/, message: "Vui lòng nhập nội dung" },
          ]}
        >
          <HTMLEditorPicker></HTMLEditorPicker>
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default PopupEditFaq;
