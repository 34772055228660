import * as React from "react";
import { Row, Col, Input, Modal } from "antd";
import StudentInClass from "component/class/student_in_class";

const { TextArea } = Input;

const PopupAttendance = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [lessonID, setLessonID] = React.useState([]);

  const close = () => {
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(lessonID) {
      setVisible(true);
      setLessonID(lessonID);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Điểm danh"
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="70%"
    >
      <StudentInClass lessonID={lessonID} />
    </Modal>
  );
});
export default PopupAttendance;
