import * as React from "react";
import { useNavigate, Outlet } from "react-router-dom";

const MenuItemPC = (props) => {
  const [color, setColor] = React.useState("white");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (window.location.pathname == props.data.path) {
      setColor("pink");
    } else setColor("white");
  }, [window.location.pathname]);

  return (
    <div
      style={{ display: "flex" }}
      onMouseLeave={() => {
        if (window.location.pathname != props.data.path) setColor("white");
      }}
      onMouseEnter={() => {
        setColor("pink");
      }}
      onClick={() => {
        navigate(props.data.path);
      }}
    >
      <div style={{ margin: "auto", padding: 15, fontWeight: "bold", fontSize: 25, color: color }}>{props.data.label}</div>
    </div>
  );
};

export default MenuItemPC;
