import React from "react";
import api from "api";
import { Space, Dropdown, Row, Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { mainColor } from "config";
import ListItem from "client_component/home/list-item";
import { useNavigate } from "react-router-dom";
import { DataLogin } from "Client";

const ClientClass = (props) => {
  const length = 20;
  const [listBlog, setListBlog] = React.useState([]);
  const [listClass, setListClass] = React.useState([]);
  const [listTarget, setListTarget] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [filterClass, setFilterClass] = React.useState({
    TagetIDs: [],
    Type: "public",
    StartRow: 0,
    Length: 20,
    SortCol: "",
    SortType: "",
    Status: "publish",
  });
  const dataLogin = React.useContext(DataLogin);

  const navigate = useNavigate();

  React.useEffect(() => {
    api.get("/ClientClass/GetListTarget").then((res) => {
      setListTarget(res.data);
    });
    api
      .post("/ClientBlog/GetList", {
        Status: "publish",
        StartRow: 0,
        Length: 4,
        SortCol: "",
        SortType: "",
      })
      .then((res) => {
        setListBlog(res.data.data);
      });
    getListClass();
  }, []);

  React.useEffect(() => {
    getListClass();
  }, [filterClass]);

  const getListClass = () => {
    var url = "/ClientClass/GetList";
    if (props.type == "my_class") url = "/Class/GetMyList";
    api.post(url, filterClass).then((res) => {
      setListClass(res.data.data);
      setTotal(res.data.total);
    });
  };

  const setFilter = (value, name) => {
    var filter = { ...filterClass };
    if (name == "TagetIDs") filter[name] = [value];
    else filter[name] = value;
    setFilterClass(filter);
  };

  const pageChange = (e) => {
    setPage(e);
    var filter = { ...filterClass };
    filter.StartRow = (e - 1) * length;
    setFilterClass(filter);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div className="container">
        <Row>
          <Col xs={0} sm={0} md={2} lg={4} xl={4}></Col>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <div style={{ display: "flex", height: 40, marginBottom: 20 }}>
              <div
                className="hide-scroll"
                style={{
                  flex: 1,
                  display: "flex",
                  padding: "5px 10px",
                  boxSizing: "border-box",
                  borderRadius: 20,
                  backgroundColor: "rgb(245 245 245)",
                  overflowX: "scroll",
                }}
              >
                <div
                  onClick={() => {
                    setFilter(null, "TagetIDs");
                  }}
                  style={{
                    padding: "5px 15px",
                    margin: "0 5px",
                    borderRadius: "white",
                    height: 30,
                    borderRadius: 15,
                    boxSizing: "border-box",
                    display: "flex",
                    backgroundColor: filterClass.TagetIDs[0] == null ? "#fff" : "transparent",
                  }}
                >
                  <div>Tất cả</div>
                </div>
                {listTarget.map((x) => {
                  return (
                    <div
                      onClick={() => {
                        setFilter(x.id, "TagetIDs");
                      }}
                      style={{
                        padding: "5px 15px",
                        margin: "0 5px",
                        borderRadius: "white",
                        height: 30,
                        borderRadius: 15,
                        boxSizing: "border-box",
                        display: "flex",
                        backgroundColor: filterClass.TagetIDs[0] == x.id ? "#fff" : "transparent",
                      }}
                    >
                      <div>{x.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col xs={0} sm={0} md={2} lg={4} xl={4}></Col>
        </Row>
      </div>
      <div className="container">
        <div style={{ boxSizing: "border-box" }}>
          <div>
            <ListItem type="class" list={listClass} pagination={true} page={page} total={total} length={length} pageChange={pageChange}></ListItem>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: 40,
            fontWeight: "bold",
            fontFamily: "CustomFont",
            color: mainColor,
            padding: "50px 0",
          }}
        >
          <div>Bài viết</div>
          <div
            className="text-hover"
            style={{ fontSize: 25 }}
            onClick={() => {
              navigate(`/blog`);
            }}
          >
            Xem thêm
          </div>
        </div>
        <div>
          <ListItem type="blog" list={listBlog}></ListItem>
        </div>
      </div>
    </div>
  );
};

export default ClientClass;
