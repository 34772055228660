import React from "react";
import api from "api";
import { Input, Dropdown, Row, Col, ConfigProvider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { mainColor } from "config";
import ListItem from "client_component/home/list-item";
import { useParams, useNavigate } from "react-router-dom";

const { Search } = Input;

const Teacher = () => {
  const [data, setData] = React.useState([]);
  const [listClass, setListClass] = React.useState([]);

  var params = useParams();

  React.useEffect(() => {
    api.get("/ClientClass/GetUserByUserName?userName=" + params.user).then((res) => {
      setData(res.data);
    });
    api
      .post("/ClientClass/GetList", {
        UserIDs: [params.user],
        StartRow: 0,
        Length: 8,
        SortCol: "",
        SortType: "",
      })
      .then((res) => {
        setListClass(res.data.data);
      });
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <div className="container">
        <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: 250, height: 250, borderRadius: "50%", overflow: "hidden", margin: 20 }}>
              <img style={{ width: "100%" }} src={data.avatar} />
            </div>
            <div style={{ fontSize: 18, padding: 20, maxWidth: 700 }}>
              <div style={{ fontSize: 35, fontWeight: "bold", marginBottom: 30 }}>{data.name}</div>
              <div style={{ marginBottom: 30 }}>{data.description}</div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 3 }}>Thông tin chi tiết:</div> <a href={data.cv_link}>{data.cv_link}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          style={{
            fontSize: 40,
            fontWeight: "bold",
            fontFamily: "CustomFont",
            color: mainColor,
            padding: "50px 0",
          }}
        >
          <div>Khóa học</div>
        </div>
        <div>
          <ListItem type="class" list={listClass}></ListItem>
        </div>
      </div>
    </div>
  );
};

export default Teacher;
