import * as React from "react";
import api from "api";
import { TimePicker, Input, Form, message, Modal, Select } from "antd";
import { documentURL } from "config";
import FileUpload from "component/picker/file_picker";

const { TextArea } = Input;

const PopupEditDocument = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [listCategory, setListCategory] = React.useState([]);

  const form = React.useRef();

  const saveDocument = (values) => {
    api
      .post("/Document/Update", values)
      .then((res) => {
        console.log(res);
        message.success("Thành công");
        if (props.reload) props.reload();
        if (props.handle) props.handle(res.data);
        close(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  const getListCategory = () => {
    api.get("/BlogCategory/GetList").then((res) => {
      setListCategory(res.data);
    });
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      getListCategory();
      setTimeout(() => {
        if (id) {
          api.get("/Document/GetByID?id=" + id).then((res) => {
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa tài liệu"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={saveDocument} layout="vertical">
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item label="Tên " name="name" rules={[{ required: true, message: "Nhập tên!" }]}>
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>
        <Form.Item label="Danh mục" name="topic" rules={[{ required: true, message: "Chọn danh mục!" }]}>
          <Select
            options={listCategory.map((x) => {
              return { value: x.id, label: x.name };
            })}
          />
        </Form.Item>

        <Form.Item name="type" label="Loại tài liệu" rules={[{ required: true, message: "Chọn loại chủ đề!" }]}>
          <Select
            options={[
              {
                value: "private",
                label: "Riêng tư",
              },
              {
                value: "public",
                label: "Công cộng",
              },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item label="Mô tả" name="description">
          <TextArea></TextArea>
        </Form.Item>

        <Form.Item name={"url"} label={"File"} rules={[{ required: true, message: "Chọn file!" }]}>
          <FileUpload type="document" url={documentURL}></FileUpload>
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default PopupEditDocument;
