import React from "react";
import { UploadOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Upload, message, Space } from "antd";
import api from "api";
import { homeworkURL, documentURL } from "config";

const FileUpload = (props) => {
  const [isUploading, setIsUpload] = React.useState(false);

  const uploadImage = (e) => {
    var form = new FormData();
    form.append("file", e.file);
    form.append("type", props.type);
    setIsUpload(true);
    api
      .post("Upload/Upload", form)
      .then((res) => {
        setIsUpload(false);
        var url = props.url + res.data[0];
        if (props.onChange) props.onChange(url);
      })
      .catch((err) => {
        message.error("Không thể tải file. Vui lòng thử lại");
        setIsUpload(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        <Upload showUploadList={false} customRequest={uploadImage}>
          <Button icon={<UploadOutlined />} loading={isUploading}>
            Upload
          </Button>
        </Upload>
      </div>
      <div>
        {props.value ? (
          <div style={{ display: "flex" }}>
            <LinkOutlined style={{ marginRight: 15 }} />
            <div style={{ width: "100%", overflow: "hidden" }}>
              <a href={props.value} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
                {props.value}
              </a>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default FileUpload;
