import * as React from "react";
import api from "api";
import { Row, Col, Input, Form, message, Modal, DatePicker, Select, Button } from "antd";
import dayjs from "dayjs";
import FilterSelect from "component/picker/filter_select_picker";
import HTMLEditorPicker from "component/picker/html_editor_picker";
import PopupEditDocument from "component/document/popup_edit_document";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const PopupEditLesson = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [listUser, setListUser] = React.useState([]);

  const form = React.useRef();
  const popupEditDocument = React.useRef();

  const saveLesson = (values) => {
    values.start_time = values.time[0].format();
    values.end_time = values.time[1].format();
    api
      .post("/Lesson/Update", { Lesson: values, Slug: props.slug })
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getlstuser = () => {
    api.get("User/GetListTeacher").then((res) => {
      setListUser(res.data);
    });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      getlstuser();
      setTimeout(() => {
        if (id) {
          api.get("/Lesson/GetByID?id=" + id).then((res) => {
            res.data.time = [dayjs(res.data.start_time), dayjs(res.data.end_time)];
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 500);
    },
  }));

  const handleInsertDocument = (data) => {
    form.current.setFieldsValue({ document_id: data.id });
  };

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa bài học"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="50%"
    >
      <Form ref={form} onFinish={saveLesson} layout="vertical">
        <Row gutter={20}>
          <Form.Item name="id" hidden></Form.Item>
          <Col span={12}>
            <Form.Item label="Tên " name="topic" rules={[{ required: true, message: "Nhập tên !" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Thời gian" name="time" rules={[{ required: true, message: "Chọn thời gian!" }]}>
              <RangePicker showTime={true}></RangePicker>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Giáo viên" name={"teacher_id"} rules={[{ required: true, message: "Chọn giáo viên!" }]}>
              <Select style={{ marginLeft: "5px" }} options={listUser} fieldNames={{ label: "name", value: "user_name" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Video" name="video">
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Nội dung"
              name="content"
              rules={[
                { required: true, message: "Vui lòng nhập nội dung" },
                { pattern: /^(?!<p><br><\/p>$)/, message: "Vui lòng nhập nội dung" },
              ]}
            >
              <HTMLEditorPicker></HTMLEditorPicker>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div style={{ display: "flex" }}>
              <Form.Item label="Tài liệu" name="document_id" style={{ marginRight: 20, flex: 1 }}>
                <FilterSelect
                  url="Document/SearchDocument?search="
                  getListByIDURL="Document/GetByID?id="
                  fieldNames={{ value: "id", label: "name" }}
                ></FilterSelect>
              </Form.Item>
              <div style={{ display: "flex", height: "auto" }}>
                <Button
                  style={{ margin: "auto" }}
                  type="primary"
                  onClick={() => {
                    popupEditDocument.current.open();
                  }}
                >
                  Thêm tài liệu
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <PopupEditDocument ref={popupEditDocument} handle={handleInsertDocument}></PopupEditDocument>
    </Modal>
  );
});
export default PopupEditLesson;
