import { mainColor } from "config";
import React from "react";
import { formatShortDate } from "common/Format2";
import { ClockCircleOutlined, LaptopOutlined, UserOutlined, DollarOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { formatCurrency } from "common/Format2";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "../style.css";

const ClassCard = (props) => {
  const navigate = useNavigate();
  return (
    <div style={{ width: "100%", borderRadius: 10, overflow: "hidden", boxShadow: "0px 4px 8px 0px #dddddd", position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        {dayjs() < dayjs(props.data.start_date) ? <Tag color="#2db7f5">Chưa diễn ra</Tag> : <></>}
        {dayjs() > dayjs(props.data.finish_date) ? <Tag color="#f50">Đã kết thúc</Tag> : <></>}
        {dayjs() >= dayjs(props.data.start_date) && dayjs() <= dayjs(props.data.finish_date) ? <Tag color="#87d068">Đang diễn ra</Tag> : <></>}
      </div>
      <div style={{ aspectRatio: 3 / 2, overflow: "hidden" }}>
        <img
          src={props.data.image}
          style={{ width: "100%" }}
          className="image-hover"
          onClick={() => {
            navigate(`/course/${props.data.slug}`);
          }}
        ></img>
      </div>
      <div
        style={{
          width: "100%",
          padding: "10px 20px",
          backgroundColor: "#f5f5f5",
          height: 220,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Geomanist,sans-serif",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", color: mainColor, flexWrap: "wrap" }}>
          <div style={{ backgroundColor: "white", padding: "4px 15px", fontSize: 15, borderRadius: 15, whiteSpace: "nowrap" }}>{props.data.target_name}</div>
        </div>
        <div
          style={{ fontSize: "1.125rem", color: mainColor, lineHeight: "145%", fontWeight: "600", flex: 1, display: "flex" }}
          onClick={() => {
            navigate(`/course/${props.data.slug}`);
          }}
        >
          <div className="text-hover text-line-2" style={{ margin: "auto" }}>
            {props.data.name}
          </div>
        </div>
        <div style={{ fontSize: "1rem", fontWeight: 400, color: "#666" }}>
          <div style={{ marginBottom: 5 }}>
            <Space>
              <ClockCircleOutlined />
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 10 }}>{formatShortDate(props.data.start_date)}</div>
                <div style={{ marginRight: 10 }}>-</div>
                <div>{formatShortDate(props.data.finish_date)}</div>
              </div>
            </Space>
          </div>
          <div style={{ marginBottom: 5 }}>
            <Space>
              <LaptopOutlined />
              <div>Học online qua Ms Team</div>
            </Space>
          </div>
          <div style={{ marginBottom: 5 }}>
            <Space>
              <UserOutlined />
              <div>{props.data.teacher_name}</div>
            </Space>
          </div>
        </div>
        <div style={{ fontSize: 25, fontWeight: 600, display: "flex", justifyContent: "flex-end", color: mainColor }}>
          {formatCurrency(props.data.tuition)}đ
        </div>
      </div>
    </div>
  );
};

export default ClassCard;
