import * as React from "react";
import api from "api";
import { Select, Input, Form, message, Modal, Row, Col, InputNumber } from "antd";
import dayjs from "dayjs";
import UploadImagePicker from "component/picker/image_picker";

const { TextArea } = Input;

const PopupEditAchievement = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();

  const form = React.useRef();

  const saveAchievement = (values) => {
    api
      .post("/Achievement/Update", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setTimeout(() => {
        if (id) {
          api.get("/Achievement/GetByID?id=" + id).then((res) => {
            res.data.start_time = dayjs(res.data.start_time);
            res.data.end_time = dayjs(res.data.end_time);
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa ca"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={saveAchievement} layout="vertical">
        <Form.Item name="id" hidden></Form.Item>

        <Form.Item label="Hình ảnh" name="image" rules={[{ required: true, message: "Nhập hình ảnh!" }]}>
          <UploadImagePicker crop={{ quality: 1, aspect: 2 / 2 }} size={{ width: 200, height: 200 }} tags={["logo"]}></UploadImagePicker>
        </Form.Item>

        <Form.Item label="Tên " name="name" rules={[{ required: true, message: "Nhập tên!" }]}>
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>
        <Form.Item label="Giá trị " name="value" rules={[{ required: true, message: "Nhập giá trị!" }]}>
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
          ></InputNumber>
        </Form.Item>
        <Form.Item label="Trạng thại" name="status" rules={[{ required: true, message: "Nhập trạng thái!" }]}>
          <Select
            options={[
              { value: "draft", label: "Bản nháp" },
              { value: "publish", label: "Xuất bản" },
            ]}
          ></Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default PopupEditAchievement;
