import * as React from "react";
import api from "api";
import { Select, Row, message, Modal, Col } from "antd";

const PopupJoinClass = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [lstClass, setLstClass] = React.useState([]);
  const [username, setUsername] = React.useState();
  const [classSelect, setClassSelect] = React.useState();

  const addStudent = () => {
    if (!classSelect) {
      message.error("Bạn chưa chọn lớp");
      return;
    }
    api
      .post(`/Class/AddUserIntoClass`, {
        Username: username,
        ClassID: classSelect,
        Type: "student",
      })
      .then((res) => {
        message.success("Thêm thành công");
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    setVisible(false);
    props.reload();
    setClassSelect(null);
  };

  React.useImperativeHandle(ref, () => ({
    open(username) {
      api.get("/Class/GetListClassActive").then((res) => {
        setLstClass(
          res.data.map((item) => {
            return { value: item.id, label: item.name };
          })
        );
      });
      setUsername(username);
      setVisible(true);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Tham gia lớp học"
      okText="Lưu"
      onOk={() => {
        addStudent();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="40%"
    >
      <Row gutter={20}>
        <Col span={6} style={{ display: "flex" }}>
          <b3 style={{ marginBottom: "auto", marginTop: "auto" }}>Lớp học</b3>{" "}
        </Col>
        <Col span={18}>
          <Select
            style={{ marginLeft: "5px", width: "100%" }}
            value={classSelect}
            onChange={(e) => {
              setClassSelect(e);
            }}
            options={lstClass}
          />
        </Col>
      </Row>
    </Modal>
  );
});
export default PopupJoinClass;
