import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Table, Space, message } from "antd";
import PopupEditBlogCategory from "./popup_blog_category";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
const BlogCategory = (props) => {
  const [lstBlogCategory, setlstBlogCategory] = React.useState([]);
  const blogCategoryPopup = React.useRef();

  React.useEffect(() => {
    getlstBlogCategory();
  }, [props.reload]);

  const getlstBlogCategory = () => {
    api.get("BlogCategory/GetList").then((res) => {
      setlstBlogCategory(res.data);
    });
  };

  const deleteBlogCategory = (id) => {
    api.get("BlogCategory/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getlstBlogCategory();
    });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              blogCategoryPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                blogCategoryPopup.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa danh mục này không"
              placement="topRight"
              onConfirm={() => {
                deleteBlogCategory(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Card>
      <Table style={{ width: "100%" }} columns={col} dataSource={lstBlogCategory} rowKey="id" />
      <PopupEditBlogCategory ref={blogCategoryPopup} reload={getlstBlogCategory}></PopupEditBlogCategory>
    </Card>
  );
};

export default BlogCategory;
