import React from "react";
import { Card } from "antd";
import InputComment from "./input_comment";
import api from "api";
import { formatLongDate } from "common/Format2";

const CommentItem = (props) => {
  const [listComment, setListComment] = React.useState([]);
  const [showChild, setShowChild] = React.useState(false);
  const [countChild, setCountChild] = React.useState(0);

  React.useEffect(() => {
    setCountChild(props.data.total_child);
  }, [props.data]);

  const getListChildComment = () => {
    api.get("ClientBlog/GetListCommentByParentID?id=" + props.data.id).then((res) => {
      setListComment(res.data);
    });
  };

  const addComment = (data) => {
    var lst = [...listComment];
    lst.push(data);
    setListComment(lst);
  };
  return (
    <div>
      <div style={{ marginBottom: 5, fontWeight: "bold" }}>{props.data.create_by}</div>
      <Card bodyStyle={{ padding: 10 }}>{props.data.comment}</Card>
      {showChild ? (
        <div
          style={{ color: "#65676B", cursor: "pointer" }}
          onClick={() => {
            setShowChild(false);
          }}
        >
          Rút gọn
        </div>
      ) : (
        <div style={{ color: "#65676B", display: "flex" }}>
          <div style={{ marginRight: 20 }}>{formatLongDate(props.data.create_date)}</div>
          <div
            style={{ color: "#65676B", cursor: "pointer" }}
            onClick={() => {
              getListChildComment();
              setShowChild(true);
            }}
          >
            {`Phản hồi ${countChild > 0 ? `(${countChild})` : ""}`}
          </div>
        </div>
      )}

      {showChild ? (
        <div style={{ padding: "5px 0px 10px 40px" }}>
          {listComment.map((x) => {
            return <CommentItem data={x} parentID={props.data.id} blogID={props.blogID}></CommentItem>;
          })}
          <InputComment blogID={props.blogID} addComment={addComment} parentID={props.data.id}></InputComment>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CommentItem;
