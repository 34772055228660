import * as React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import "../../index.css";
import { MenuOutlined, MailOutlined, LockOutlined, CloseOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, Modal, message, Button } from "antd";
import { mainColor } from "config";
import LoginFirebase from "./login-firebase";
import OTPInput, { ResendOTP } from "otp-input-react";
import api from "api";

const LoginPopup = React.forwardRef((props, ref) => {
  const [color, setColor] = React.useState("white");
  const [visible, setVisible] = React.useState(false);
  const [mode, setMode] = React.useState("login");
  const [objLabel, setObjLabel] = React.useState({});
  const [isOTP, setIsOTP] = React.useState(false);
  const [OTP, setOTP] = React.useState(false);
  const [countdown, setCountdown] = React.useState(60);
  const [dataForm, setDataForm] = React.useState({});
  const [apiUrl, setApiurl] = React.useState({});
  const [disableButton, setDisableButton] = React.useState(false);
  const form = React.useRef();
  const handleCowndown = React.useRef();
  const navigate = useNavigate();
  const intervalCountdown = React.useRef();

  React.useEffect(() => {
    if (!mode || mode == "signin") {
      setObjLabel({
        email: "Email",
        password: "Mật khẩu",
        button: "Đăng nhập",
      });
      setApiurl({
        url: "/Login/Login",
      });
    }
    if (mode == "signup") {
      setObjLabel({
        email: "Email",
        password: "Mật khẩu",
        rePassword: "Nhập lại mật khẩu",
        button: "Đăng ký",
      });
      setApiurl({
        url: "/Register/OtpRegister",
        url2: "/Register/NormalRegister",
      });
    }
    if (mode == "forget") {
      setObjLabel({
        email: "Email",
        password: "Mật khẩu mới",
        rePassword: "Nhập lại mật khẩu mới",
        button: "Đặt lại mật khẩu",
      });
      setApiurl({
        url: "/Login/OtpForgetPassword",
        url2: "/Login/ChangePassword",
      });
    }
  }, [mode]);

  const onFinish = (values) => {
    setDisableButton(true);
    setDataForm(values);
    api
      .post(apiUrl.url, values)
      .then((res) => {
        console.log("res", res);
        if (mode == "signin") {
          message.success("Đăng nhập thành công");
          props.checkLogin();
          close();
        } else {
          setIsOTP(true);
          intervalCountdown.current = setInterval(() => {
            handleCowndown.current();
          }, 1000);
        }
        setDisableButton(false);
      })
      .catch((err) => {
        message.error(err.message);
        setDisableButton(false);
      });
  };

  const checkOtpAndProcessData = () => {
    api
      .post(apiUrl.url2, { Code: OTP, Form: dataForm })
      .then((res) => {
        message.success("Đăng ký thành công");
        setIsOTP(false);
        if (intervalCountdown.current) {
          clearInterval(intervalCountdown.current);
          intervalCountdown.current = null;
        }
        setCountdown(60);
        setOTP();
        setMode("signin");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  handleCowndown.current = () => {
    if (countdown == 0)
      if (intervalCountdown.current) {
        clearInterval(intervalCountdown.current);
        intervalCountdown.current = null;
      }
    setCountdown((cur) => {
      cur = cur - 1;
      if (cur < 0) cur = 0;
      return cur;
    });
  };

  const close = () => {
    setIsOTP(false);
    if (intervalCountdown.current) {
      clearInterval(intervalCountdown.current);
      intervalCountdown.current = null;
    }
    setCountdown(60);
    setOTP();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(mode) {
      setIsOTP(false);
      if (intervalCountdown.current) {
        clearInterval(intervalCountdown.current);
        intervalCountdown.current = null;
      }
      setCountdown(60);
      setOTP();
      setMode(mode ?? "signin");
      setVisible(true);
      form.current.resetFields();
    },
  }));

  return (
    <div hidden={!visible}>
      <div>
        <div
          className="overlay"
          onClick={() => {
            close();
          }}
        ></div>
      </div>
      <div style={{ position: "fixed", display: "flex", margin: "auto", top: 0, bottom: 0, left: 0, right: 0, zIndex: 300 }}>
        <div className="popup-login">
          <div style={{ position: "absolute", right: 10, top: 10, zIndex: 300 }}>
            <Button
              type="link"
              onClick={() => {
                close();
              }}
              style={{ padding: 0, height: 20, width: 20 }}
            >
              <CloseOutlined style={{ color: mainColor, fontSize: 20 }} />
            </Button>
          </div>
          <Row style={{ height: "100%" }}>
            <Col xxl={12} xl={12} lg={0} md={0} sm={0} xs={0} style={{ padding: 20 }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url("/image/Mascot.jpg")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  borderRadius: 22,
                }}
              ></div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    margin: "auto",
                    width: "100%",
                    margin: 20,
                    border: `1px solid ${mainColor}`,
                    borderRadius: 22,
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <div style={{ width: "100%", display: "flex", marginBottom: 20 }}>
                      <img style={{ margin: "auto", height: 70 }} src="/image/logo1.png" alt="" />
                    </div>

                    {isOTP ? (
                      <>
                        <div style={{ width: "100%", display: "flex", marginBottom: 20 }}>
                          <div style={{ fontFamily: "CustomFont", color: mainColor, margin: "auto", fontSize: 30, fontWeight: "bold" }}>Nhập mã xác thực</div>
                        </div>
                        <div style={{ width: "100%", display: "flex", marginBottom: 20 }}>
                          <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            inputStyles={{ width: 45, height: 45, margin: "unset" }}
                            style={{ justifyContent: "space-between", width: "100%" }}
                          />
                        </div>
                        <div style={{ width: "100%", display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                          <div style={{ marginTop: "auto", marginBottom: "auto" }}>{`Vui lòng chờ  ${countdown}s`}</div>
                          <div>
                            <Button style={{ marginTop: "auto", marginBottom: "auto", padding: 0 }} type="link" disabled={countdown != 0}>
                              Gửi lại
                            </Button>
                          </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                          <Button
                            onClick={() => {
                              checkOtpAndProcessData();
                            }}
                            size="large"
                            style={{
                              margin: "auto",
                              width: 300,
                              fontFamily: "CustomFont",

                              backgroundColor: mainColor,
                              color: "white",
                              borderRadius: 50,
                              fontSize: 18,
                            }}
                          >
                            {objLabel.button}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Form
                          name="basic"
                          ref={form}
                          wrapperCol={{ span: 24 }}
                          initialValues={{ remember: true }}
                          onFinish={onFinish}
                          autoComplete="off"
                          style={{
                            width: 300,
                          }}
                        >
                          <Form.Item
                            name="user_name"
                            rules={[
                              {
                                required: true,
                                message: "Bạn chưa nhập email!",
                              },
                              {
                                type: "email",
                                message: "Email không hợp lệ!",
                              },
                            ]}
                          >
                            <Input prefix={<MailOutlined />} style={{ fontSize: 20, borderRadius: 50 }} placeholder={objLabel.email} />
                          </Form.Item>

                          <Form.Item name="password" rules={[{ required: true, message: "Bạn chưa nhập password!" }]}>
                            <Input.Password prefix={<LockOutlined />} style={{ fontSize: 20, borderRadius: 50 }} placeholder={objLabel.password} />
                          </Form.Item>
                          {mode != "signin" ? (
                            <Form.Item
                              name="confirm"
                              dependencies={["password"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Bạn chưa nhập password!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("Mật khẩu không trùng khớp!"));
                                  },
                                }),
                              ]}
                            >
                              <Input.Password prefix={<LockOutlined />} style={{ fontSize: 20, borderRadius: 50 }} placeholder={objLabel.rePassword} />
                            </Form.Item>
                          ) : (
                            <></>
                          )}

                          <Form.Item wrapperCol={{ span: 24 }}>
                            <div style={{ textAlign: "center" }}>
                              <Button
                                disabled={disableButton}
                                htmlType="submit"
                                size="large"
                                style={{
                                  width: 300,
                                  fontFamily: "CustomFont",

                                  backgroundColor: mainColor,
                                  color: "white",
                                  borderRadius: 50,
                                  fontSize: 18,
                                }}
                              >
                                {objLabel.button}
                              </Button>
                            </div>
                            {mode == "signin" ? (
                              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                  type="link"
                                  style={{ color: mainColor }}
                                  onClick={() => {
                                    setMode("forget");
                                  }}
                                >
                                  Quên mật khẩu?
                                </Button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </Form.Item>
                        </Form>

                        <div style={{ width: "100%", display: "flex", marginBottom: 20 }}>
                          <div style={{ margin: "auto", width: "80%", borderBottom: `1px solid ${mainColor}` }} />
                        </div>

                        <LoginFirebase checkLogin={props.checkLogin} close={close} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
});

export default LoginPopup;
