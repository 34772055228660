import React from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, getRedirectResult } from "firebase/auth";
import { Row, Col, Form, Input, Modal, message, Button, Space } from "antd";
import { mainColor } from "config";
import api from "api";

const LoginFirebase = (props) => {
  const firebaseConfig = {
    apiKey: "AIzaSyC0pPgMV7c4voo5_-XQRtYIDJ7aDWWJXLY",
    authDomain: "shaped-inverter-317503.firebaseapp.com",
    projectId: "shaped-inverter-317503",
    storageBucket: "shaped-inverter-317503.appspot.com",
    messagingSenderId: "907719763805",
    appId: "1:907719763805:web:a0f9369099c315f8f7eab4",
    measurementId: "G-T3P014Y9LK",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const providerGG = new GoogleAuthProvider();
  const providerFB = new FacebookAuthProvider();
  providerFB.setCustomParameters({
    display: "popup",
  });
  providerFB.addScope("public_profile,pages_show_list");
  const auth = getAuth();
  auth.languageCode = "vi";

  const signInWithGoogle = () => {
    signInWithPopup(auth, providerGG)
      .then((result) => {
        console.log("result", result);
        api
          .post("/Login/LoginGoogle", {
            Name: result._tokenResponse.fullName,
            Email: result._tokenResponse.email,
            Token: result._tokenResponse.oauthAccessToken,
          })
          .then((res) => {
            message.success("Đăng nhập thành công");
            props.checkLogin();
            props.close();
          })
          .catch((err) => {
            message.error(err.message);
          });
      })
      .catch((error) => {
        message.error("Đăng nhập google thất bại");
      });
  };

  const signInWithFB = () => {
    signInWithPopup(auth, providerFB)
      .then((result) => {
        console.log(result);
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
      });
  };

  const responseFacebook = (data) => {
    console.log(data);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <div style={{ display: "flex", marginBottom: 10 }}>
          <div style={{ margin: "auto", fontWeight: 600, color: mainColor }}>Hoặc đăng nhập với:</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Space>
            <Button type="link" onClick={signInWithGoogle}>
              <svg width="40" height="40" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3133_6828)">
                  <path
                    d="M20.3042 10.2302C20.3042 9.55044 20.2491 8.86699 20.1315 8.19824H10.6992V12.0491H16.1007C15.8765 13.291 15.1563 14.3897 14.1018 15.0878V17.5864H17.3243C19.2166 15.8448 20.3042 13.2726 20.3042 10.2302Z"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M10.7019 20.0008C13.3989 20.0008 15.6734 19.1152 17.3306 17.5867L14.1081 15.088C13.2115 15.698 12.0541 16.0434 10.7056 16.0434C8.09669 16.0434 5.88468 14.2833 5.091 11.917H1.76562V14.4928C3.46322 17.8696 6.92087 20.0008 10.7019 20.0008Z"
                    fill="#34A853"
                  ></path>
                  <path
                    d="M5.08467 11.9168C4.66578 10.6748 4.66578 9.32995 5.08467 8.08799V5.51221H1.76297C0.344635 8.33785 0.344635 11.6669 1.76297 14.4925L5.08467 11.9168Z"
                    fill="#FBBC04"
                  ></path>
                  <path
                    d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805Z"
                    fill="#EA4335"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_3133_6828">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"></rect>
                  </clipPath>
                </defs>
              </svg>
            </Button>
            {/* <Button type="link" onClick={signInWithFB}>
              <svg width="44" height="44" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3133_3118)">
                  <path
                    d="M20.5 10C20.5 4.47715 16.0229 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 14.9912 4.15684 19.1283 8.9375 19.8785V12.8906H6.39844V10H8.9375V7.79688C8.9375 5.29063 10.4305 3.90625 12.7146 3.90625C13.8084 3.90625 14.9531 4.10156 14.9531 4.10156V6.5625H13.6922C12.45 6.5625 12.0625 7.3334 12.0625 8.125V10H14.8359L14.3926 12.8906H12.0625V19.8785C16.8432 19.1283 20.5 14.9912 20.5 10Z"
                    fill="#1877F2"
                  ></path>
                  <path
                    d="M14.3926 12.8906L14.8359 10H12.0625V8.125C12.0625 7.33418 12.45 6.5625 13.6922 6.5625H14.9531V4.10156C14.9531 4.10156 13.8088 3.90625 12.7146 3.90625C10.4305 3.90625 8.9375 5.29063 8.9375 7.79688V10H6.39844V12.8906H8.9375V19.8785C9.97287 20.0405 11.0271 20.0405 12.0625 19.8785V12.8906H14.3926Z"
                    fill="white"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_3133_3118">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"></rect>
                  </clipPath>
                </defs>
              </svg>
            </Button> */}
          </Space>
        </div>
      </div>
    </div>
  );
};
export default LoginFirebase;
