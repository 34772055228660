import React from "react";
import AntTable from "component/common/AntTable";
import { Modal, Button, Card, Row, Col, Input, Space, message, Popconfirm } from "antd";
import { formatShortDate } from "common/Format2";
import { SearchOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import FilterSelect from "component/picker/filter_select_picker";
import PopupEditComment from "./popup_edit_comment";
import api from "api";

const PopupComment = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [id, setID] = React.useState();
  const [filter, setFilter] = React.useState({});
  const tblComment = React.useRef();
  const popupEditComment = React.useRef();

  const getLstComment = () => {
    tblComment.current.reload();
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const deleteComment = (id) => {
    api
      .get("Blog/DeleteComment?id=" + id)
      .then((res) => {
        message.success("Xóa thành công");
        getLstComment();
      })
      .catch((err) => {
        message.err(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setID(id);
      setTimeout(() => {
        getLstComment();
      }, 300);
    },
  }));

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },

    {
      title: "Bình luận",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },
    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              popupEditComment.current.open(id);
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                popupEditComment.current.open(id, record.id);
              }}
            >
              <EditOutlined />
            </Button>

            <Popconfirm
              title="Bạn có muốn xóa comment này không"
              placement="topRight"
              onConfirm={() => {
                deleteComment(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      width="70%"
      open={visible}
      title={"Bài viết liên quan"}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[]}
    >
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Nhân viên</div>
              <FilterSelect
                url="User/SearchUser?search="
                getListByIDURL="Document/GetByUsername?username="
                fieldNames={{ value: "user_name", label: "name" }}
                onChange={(e) => {
                  paramChange(e.target.value, "UserName");
                }}
              ></FilterSelect>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.status}
                style={{
                  width: "100%",
                }}
                onPressEnter={() => {
                  getLstComment();
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstComment();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>

      <Card>
        <AntTable columns={col} rowKey="id" ajax="Blog/GetListComment" customSearch={{ ...filter, BlogID: id }} ref={tblComment}></AntTable>
      </Card>
      <PopupEditComment ref={popupEditComment} reload={getLstComment}></PopupEditComment>
    </Modal>
  );
});
export default PopupComment;
