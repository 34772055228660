import React from "react";
import { Row, Col, Pagination } from "antd";
import BlogCard from "./blog-card";
import ClassCard from "./class_card";

const ListItem = (props) => {
  return (
    <Row gutter={[20, 20]}>
      {props.list.map((x) => {
        if (props.type == "blog")
          return (
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
              <BlogCard data={x} />
            </Col>
          );
        if (props.type == "class")
          return (
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
              <ClassCard data={x} />
            </Col>
          );
      })}
      {props.pagination ? (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Pagination current={props.page} total={props.total} onChange={props.pageChange} pageSize={props.length} showSizeChanger={false}></Pagination>
        </div>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default ListItem;
