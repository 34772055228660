import React from "react";
import { Modal, Table, Popconfirm, message, Button, Space, Card, Input, Row, Col, Rate, Select } from "antd";
import { useParams } from "react-router-dom";
import api from "api";
import { PlusOutlined, DeleteOutlined, DollarOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import PopupAddStudent from "./popup_add_student";
const { confirm } = Modal;

const StudentInClass = (props) => {
  const [listStudent, setListStudent] = React.useState([]);
  const [listAtt, setListAtt] = React.useState([]);
  const [info, setInfo] = React.useState("");
  const [dataClass, setDataClass] = React.useState("");
  const popupAddStudent = React.useRef();
  var params = useParams();

  React.useEffect(() => {
    if (props.lessonID) getListAtt();
    api.get("/Class/GetBySlug?slug=" + params.slug).then((res) => {
      setDataClass(res.data);
    });
    getlistStudent();
  }, [props.reload, props.lessonID]);

  const getlistStudent = () => {
    api.get(`/Class/GetListStudentByClass?slug=${params.slug}&info=${info}`).then((res) => {
      setListStudent(res.data);
    });
  };

  const getListAtt = () => {
    api.get("/Attendance/GetListAttendance?lesson_id=" + props.lessonID).then((res) => {
      setListAtt(res.data);
    });
  };

  const deleteStudent = (id) => {
    api.get("Class/DeleteStudentInClass?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getlistStudent();
    });
  };

  const showPaidConfirm = (value, record) => {
    confirm({
      title: "Bạn có muôn tạo phiếu thu chi tự động không?",
      icon: <ExclamationCircleFilled />,
      content: "Hệ thống sẽ tự đông tạo phiếu thu chi theo theo tin khóa học",
      onOk() {
        paidTuition(value, record, true);
      },
      onCancel() {
        paidTuition(value, record, false);
      },
    });
  };

  const paidTuition = (value, record, createRE) => {
    api.get(`/Class/PaidTuition?id=${record.id}&paid=${value}&createRE=${createRE}`).then((x) => {
      message.success("Thành công");
      getlistStudent();
    });
  };

  const attendance = (username, value) => {
    api
      .get(`/Attendance/CreateAttendance?lesson_id=${props.lessonID}&username=${username}&value=${value}`)
      .then((res) => {
        message.success("Điểm danh thành công");
        getlistStudent();
        getListAtt();
      })
      .catch((err) => {
        message.error("Điểm danh thất bại");
      });
  };

  const changeStatus = (value, id) => {
    api
      .get(`/Class/ChangeStatusStudentInClass?id=${id}&status=${value}`)
      .then((res) => {
        message.success("Điểm danh thành công");
        getlistStudent();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trường",
      dataIndex: "school",
      key: "school",
    },
    {
      title: "Lớp",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "Đóng học phí",
      dataIndex: "is_paid",
      key: "is_paid",
      render: (text, record, index) => {
        return (
          <Rate
            value={record.paid}
            allowClear={true}
            count={dataClass.collection_times}
            character={<DollarOutlined />}
            onChange={(e) => {
              showPaidConfirm(e, record);
            }}
          />
        );
      },
    },
    {
      title: "Trạng thái",
      render: (text, record, index) => {
        return (
          <Select
            style={{ width: "100%" }}
            value={record.status}
            options={[
              {
                value: "cooperating",
                label: "Đang học",
              },
              {
                value: "resting",
                label: "Đã nghỉ",
              },
            ]}
            onChange={(e) => {
              changeStatus(e, record.id);
            }}
          ></Select>
        );
      },
    },
    {
      title: "Điểm điểm danh",
      dataIndex: "sum_point",
      key: "sum_point",
      render: (text, record, index) => {
        if (text) return Math.floor((record.sum_point / record.total_lesson) * 100) / 100;
        else return 0;
      },
    },
    {
      title: () => {
        return (
          <Button
            type="primary"
            onClick={() => {
              popupAddStudent.current.open(params.slug);
            }}
          >
            <PlusOutlined /> Thêm học sinh
          </Button>
        );
      },
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Popconfirm
              title="Bạn có muốn xóa học sinh này không"
              placement="topRight"
              onConfirm={() => {
                deleteStudent(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const colAtt = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trường",
      dataIndex: "school",
      key: "school",
    },
    {
      title: "Lớp",
      dataIndex: "grade",
      key: "class",
    },
    {
      title: "Điểm danh",
      render: (text, record, index) => {
        var check = listAtt.find((x) => x.user_id == record.user_name);
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Select
              style={{ width: 150 }}
              value={check != null ? check.is_attendanced : null}
              onChange={(e) => {
                attendance(record.user_name, e);
              }}
              allowClear
              options={[
                {
                  value: "live",
                  label: "Học trực tiếp",
                },
                {
                  value: "review",
                  label: "Xem lại",
                },
              ]}
            ></Select>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row>
          <Col span={6}>
            <div>Thông tin</div>
            <Input
              onChange={(e) => {
                setInfo(e.target.value);
              }}
            ></Input>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={(e) => {
              getlistStudent();
            }}
          >
            Tìm kiếm
          </Button>
        </div>
      </Card>
      <Card>
        <Table style={{ width: "100%" }} columns={props.lessonID ? colAtt : col} dataSource={listStudent} rowKey="id" />
        <PopupAddStudent ref={popupAddStudent} reload={getlistStudent} class={dataClass}></PopupAddStudent>
      </Card>
    </>
  );
};
export default StudentInClass;
