import React from "react";
import { Input, Button, ConfigProvider, message } from "antd";
import { mainColor } from "config";
import api from "api";
import InputComment from "./input_comment";
import CommentItem from "./comment_item";

const Comment = (props) => {
  const [comment, setComment] = React.useState();
  const [listComment, setListComment] = React.useState([]);

  React.useEffect(() => {
    getListComment();
  }, [props.blogID]);

  const getListComment = () => {
    if (props.blogID)
      api.get("ClientBlog/GetListCommentByBlogID?blogID=" + props.blogID).then((res) => {
        setListComment(res.data);
      });
  };

  const addComment = (cmt) => {
    var lst = [...listComment];
    lst.push(cmt);
    setListComment(lst);
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div style={{ fontSize: 20, padding: 10 }}>Bình luận</div>
      {listComment && listComment.length > 0 ? (
        <div style={{ maxHeight: 500, overflowY: "scroll", padding: 20, boxSizing: "border-box" }}>
          {listComment.map((x) => (
            <CommentItem data={x} blogID={props.blogID}></CommentItem>
          ))}
        </div>
      ) : (
        <></>
      )}

      <InputComment blogID={props.blogID} addComment={addComment}></InputComment>
    </div>
  );
};
export default Comment;
