import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Space, message, Row, Col, Select, Input, Tag, Tooltip } from "antd";
import PopupEditclass from "./popup_edit_class";
import { EditOutlined, DeleteOutlined, PlusOutlined, CopyOutlined, SearchOutlined, BranchesOutlined, TeamOutlined, ScheduleOutlined } from "@ant-design/icons";
import { formatLongDate, formatShortDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import PopupRelatedClass from "./popup_related_class";
import { useNavigate } from "react-router-dom";

const Class = (props) => {
  const [filter, setFilter] = React.useState({});
  const [lstUser, setListUser] = React.useState([]);
  const [lstTaget, setListTaget] = React.useState([]);
  const [isTeacher, setIsTeacher] = React.useState(false);
  const classPopup = React.useRef();
  const tbClass = React.useRef();
  const popupRelatedClass = React.useRef();

  const navigate = useNavigate();

  React.useEffect(() => {
    getListTaget();
    getListTeacher();
    if (window.userLogin.role == 3) {
      setIsTeacher(true);
      setFilter({ UserIDs: [window.userLogin.user_name] });
    }
    setTimeout(() => {
      getLstClass();
    }, 500);
  }, []);

  const getListTeacher = () => {
    api.get("User/GetListTeacher").then((res) => {
      setListUser(res.data);
    });
  };
  const getListTaget = () => {
    api.get("Target/GetList").then((res) => {
      setListTaget(res.data);
    });
  };

  const copyClass = (id) => {
    api
      .get("/Class/CopyClass?id=" + id)
      .then((res) => {
        getLstClass();
        message.success("Thành công");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getLstClass = () => {
    tbClass.current.reload();
  };

  const deleteclass = (id) => {
    api.get("Class/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getLstClass();
    });
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>Hình ảnh</div>,
      dataIndex: "image",
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={record.image} />
          </>
        );
      },
    },
    {
      title: <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>Tên</div>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>Trạng thái</div>,
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "draft") return <Tag>Nháp</Tag>;
        if (text == "publish") return <Tag color="#108ee9">Công khai</Tag>;
      },
    },
    {
      title: <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>Giáo viên</div>,
      dataIndex: "teacher_name",
      key: "teacher_name",
    },
    {
      title: <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>Mục tiêu</div>,
      dataIndex: "target_name",
      key: "target_name",
    },
    {
      title: <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>Ngày bắt đầu</div>,
      dataIndex: "start_date",
      key: "start_date",
      render: (text, record, index) => {
        if (text) return formatShortDate(text);
      },
    },
    {
      title: <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>Ngày hoàn thành</div>,
      dataIndex: "finish_date",
      key: "finish_date",
      render: (text, record, index) => {
        if (text) return formatShortDate(text);
      },
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              classPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <div>
            <div style={{ padding: 8 }}>
              <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                <Tooltip title="Sửa">
                  <Button
                    type="primary"
                    onClick={() => {
                      classPopup.current.open(record.id);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title={"Coppy"}>
                  <Button
                    type="primary"
                    onClick={() => {
                      copyClass(record.id);
                    }}
                  >
                    <CopyOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title={"Khóa học liên quan"}>
                  <Button
                    type="primary"
                    onClick={() => {
                      popupRelatedClass.current.open(record.id);
                    }}
                  >
                    <BranchesOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </div>
            <div>
              <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                <Tooltip title={"Học sinh"}>
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate(`/admin/course/${record.slug}/student`);
                    }}
                  >
                    <TeamOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title={"Buổi học"}>
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate(`/admin/course/${record.slug}/lesson`);
                    }}
                  >
                    <ScheduleOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title={"Xóa"}>
                  <Popconfirm
                    title="Bạn có muốn xóa lớp học này không"
                    placement="topRight"
                    onConfirm={() => {
                      deleteclass(record.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Space>
            </div>
          </div>
        );
      },
    },
  ];

  const col2 = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={record.image} />
          </>
        );
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "draft") return <Tag>Nháp</Tag>;
        if (text == "publish") return <Tag color="#108ee9">Công khai</Tag>;
      },
    },
    {
      title: "Giáo viên",
      dataIndex: "teacher_name",
      key: "teacher_name",
    },
    {
      title: "Mục tiêu",
      dataIndex: "target_name",
      key: "target_name",
    },
    {
      title: "Học phí",
      dataIndex: "tuition",
      key: "tuition",
    },
    {
      title: "Doanh thu",
      dataIndex: "revenue",
      key: "revenue",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "start_date",
      key: "start_date",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },
    {
      title: "Ngày hoàn thành",
      dataIndex: "finish_date",
      key: "finish_date",
      render: (text, record, index) => {
        if (text) return formatShortDate(text);
      },
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              classPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/admin/course/${record.slug}/student`);
              }}
            >
              <TeamOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/admin/course/${record.slug}/lesson`);
              }}
            >
              <ScheduleOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Taget</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.TagetIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "TagetIDs");
                }}
                options={lstTaget}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Giáo viên</div>
              <Select
                disabled={isTeacher}
                fieldNames={{ label: "name", value: "user_name" }}
                value={filter.UserIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "UserIDs");
                }}
                options={lstUser}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái</div>
              <Select
                value={filter.Type}
                onChange={(e) => {
                  paramChange(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                allowClear
                options={[
                  { value: "draft", label: "Bản nháp" },
                  { value: "publish", label: "Công khai" },
                ]}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.Info}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
                onPressEnter={() => {
                  getLstClass();
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstClass();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable columns={isTeacher ? col2 : col} ajax="Class/GetList" customSearch={filter} ref={tbClass}></AntTable>
        <PopupEditclass ref={classPopup} reload={getLstClass}></PopupEditclass>
        <PopupRelatedClass ref={popupRelatedClass} reload={getLstClass} lstUser={lstUser} lstTaget={lstTaget}></PopupRelatedClass>
      </Card>
    </>
  );
};

export default Class;
