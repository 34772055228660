import React from "react";
import { Input, Button } from "antd";
import { toNoUnicode } from "common/Text";
import { myDomain } from "config";
import { useNavigate } from "react-router-dom";

const Slug = (props) => {
  const navigate = useNavigate();
  const generateLink = (value) => {
    return myDomain + "/couse/" + toNoUnicode(value, true);
  };

  const onChange = (value) => {
    props.onChange(value);
  };

  return (
    <div>
      <Input
        value={props.value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      ></Input>
      <div>
        Link:
        <Button
          type="link"
          onClick={() => {
            navigate(generateLink(props.value));
          }}
        >{` ${generateLink(props.value)}`}</Button>
      </div>
    </div>
  );
};
export default Slug;
