import React from "react";
import api from "api";
import { Row, Col } from "antd";
import CountUp from "react-countup";

const AchievementItem = (props) => {
  const [listAchievement, setListAchievement] = React.useState([]);

  React.useEffect(() => {
    api.get("/ClientAchievement/GetList").then((res) => {
      setListAchievement(res.data);
    });
  }, []);

  return (
    <div className="container">
      <Row gutter={30}>
        <div style={{ width: "100%", backgroundImage: "radial-gradient(ellipse at center, #001c66 0, rgba(255, 255, 255, 0) 70%)", height: 4 }}></div>
        {listAchievement.map((x, index) => {
          return (
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <div style={{ width: "100%", display: "flex" }}>
                <div
                  style={{
                    margin: "auto",
                    backgroundImage: "linear-gradient(#001c66, rgba(255, 255, 255, 0))",
                    height: index % 2 == 0 ? 65 : 35,
                    width: 3,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 5,
                      width: 10,
                      height: 10,
                      borderRadius: 15,
                      border: "4px solid  #001c66",
                      backgroundColor: "rgb(220 242 242)",
                      top: -10,
                      left: -7,
                    }}
                  ></div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  paddingBottom: 5,
                  boxSizing: "border-box",
                  overflow: "hidden",
                  boxShadow: "0 13px 14px 0 #00000017",
                  borderRadius: 10,
                  background: "linear-gradient(90deg, #00adef, #001c66)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    backgroundColor: "white",
                    padding: 20,
                    boxSizing: "border-box",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      marginTop: "-60%",
                      aspectRatio: 1,
                      backgroundImage: "linear-gradient(90deg, #e8f7fd, #e9ecf2)",
                      borderRadius: "50%",
                      padding: 20,
                      marginBottom: 50,
                    }}
                  >
                    <div style={{ backgroundColor: "white", width: "100%", height: "100%", borderRadius: "50%" }}></div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-30px",
                        height: 100,
                        display: "flex",
                        right: 0,
                        left: 0,
                      }}
                    >
                      <div
                        style={{
                          margin: "auto",
                          height: 100,
                          width: 100,
                          borderRadius: "50%",
                          backgroundColor: "white",
                          padding: 20,
                          display: "flex",
                          boxSizing: "border-box",
                          boxShadow: "0 13px 14px 0 rgba(129, 104, 145, 0.05)",
                        }}
                      >
                        <img src={x.image} alt="" style={{ width: "100%", boxShadow: "0 13px 14px 0 rgba(129, 104, 145, 0.05)" }} />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <div style={{ margin: "auto", fontSize: 50, fontWeight: "bold", color: "#192335" }}>
                      <CountUp end={x.value} separator="," enableScrollSpy={true} duration={0.5} scrollSpyOnce={true} />+
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <div style={{ margin: "auto", fontSize: 18, color: "#6b7385", fontWeight: 470 }}>{x.name}</div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default AchievementItem;
