import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Space, message, Row, Col, Select, Input, Tag } from "antd";
import PopupEditDocument from "./popup_edit_document";
import { EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { formatLongDate } from "common/Format2";
import AntTable from "component/common/AntTable";

const Document = (props) => {
  const [filter, setFilter] = React.useState({});
  const [listCategory, setListCategory] = React.useState([]);
  const documentPopup = React.useRef();
  const tbDocument = React.useRef();

  React.useEffect(() => {
    getListCategory();
    getLstDocument();
  }, []);

  const getLstDocument = () => {
    tbDocument.current.reload();
  };

  const getListCategory = () => {
    api.get("/BlogCategory/GetList").then((res) => {
      setListCategory(res.data);
    });
  };

  const deletedocument = (id) => {
    api.get("Document/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getLstDocument();
    });
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <Button type="link" url={record.url}>
            {record.name}
          </Button>
        );
      },
    },
    {
      title: "Chủ đề",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Loại",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        if (text == "public") return <Tag color="green">Công cộng</Tag>;
        return <Tag color="orange">Riêng tư</Tag>;
      },
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(text);
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              documentPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                documentPopup.current.open(record.id);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Bạn có muốn xóa lớp học này không"
              placement="topRight"
              onConfirm={() => {
                deletedocument(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Danh mục</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.CategoryIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "CategoryIDs");
                }}
                options={listCategory}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Loại</div>
              <Select
                style={{ width: "100%" }}
                value={filter.Type}
                options={[
                  {
                    value: "private",
                    label: "Riêng tư",
                  },
                  {
                    value: "public",
                    label: "Công cộng",
                  },
                ]}
                onChange={(e) => {
                  paramChange(e, "Type");
                }}
                allowClear
              ></Select>
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.Info}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
                onPressEnter={() => {
                  getLstDocument();
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstDocument();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable columns={col} ajax="Document/GetList" customSearch={filter} ref={tbDocument}></AntTable>
        <PopupEditDocument ref={documentPopup} reload={getLstDocument}></PopupEditDocument>
      </Card>
    </>
  );
};

export default Document;
