import * as React from "react";
import api from "api";
import { Select, Input, Form, message, Modal, Table, Row, Col, InputNumber, Checkbox, Card, DatePicker } from "antd";
import { setAppLoading } from "component/common/loading";
import dayjs from "dayjs";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import UploadImagePicker from "component/picker/image_picker";
import Slug from "component/picker/slug_picker";
import { toNoUnicode } from "common/Text";
import { useNavigate } from "react-router-dom";
import { myDomain } from "config";
import HTMLEditorPicker from "component/picker/html_editor_picker";
import MediaFBDisplay from "./media_fb_display";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const PopupEditBlog = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [listCategory, setListCategory] = React.useState([]);
  const [type, setType] = React.useState({});
  const [attachments, setAttachments] = React.useState([]);
  const form = React.useRef();

  const navigate = useNavigate();

  const saveBlog = (values) => {
    api
      .post("/Blog/Update", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getListCategory = () => {
    api.get("/BlogCategory/GetList").then((res) => {
      setListCategory(res.data);
    });
  };

  const close = () => {
    form.current.resetFields();
    props.reload();
    setVisible(false);
  };

  const nameChange = (text) => {
    form.current.setFieldsValue({ slug: toNoUnicode(text, true) });
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      //setAppLoading(true);
      getListCategory();
      setVisible(true);
      setTimeout(() => {
        if (id) {
          api.get("/Blog/GetByID?id=" + id).then((res) => {
            form.current.setFieldsValue(res.data);
            setType(res.data.type);
            var att = JSON.parse(res.data.attachments);
            setAttachments(att);
          });
        } else {
          form.current.setFieldsValue({ status: "draft" });
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa lớp"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="80%"
    >
      <Row style={{ display: "flex" }} gutter={20}>
        <Col span={24}>
          <Card>
            <Form
              ref={form}
              onFinish={(values) => {
                saveBlog(values);
              }}
              layout="vertical"
            >
              <Form.Item name="id" hidden></Form.Item>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item label="Tên " name="name" rules={[{ required: true, message: "Nhập tên bài viết!" }]}>
                    <Input
                      onChange={(e) => {
                        nameChange(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Slug " name="slug" rules={[{ required: true, message: "Nhập slug!" }]}>
                    <Slug />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Hình ảnh" name="image">
                    <UploadImagePicker crop={{ quality: 1, aspect: 3 / 2 }} size={{ width: 300, height: 200 }} tags={["logo"]}></UploadImagePicker>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Trạng thái" name="status">
                    <Select
                      options={[
                        { value: "draft", label: "Bản nháp" },
                        { value: "publish", label: "Xuất bản" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Danh mục" name="category_id" rules={[{ required: true, message: "Chọn danh mục!" }]}>
                    <Select
                      options={listCategory.map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Mô tả ngắn" name="description">
                    <TextArea rows={8} wrap="off" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Nội dung"
                    name="content"
                    rules={[
                      { required: true, message: "Vui lòng nhập nội dung" },
                      { pattern: /^(?!<p><br><\/p>$)/, message: "Vui lòng nhập nội dung" },
                    ]}
                  >
                    <HTMLEditorPicker></HTMLEditorPicker>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          {type == "fb_post" && attachments ? (
            <Card title="FB media" style={{ marginTop: 20 }}>
              <>
                {attachments.media ? (
                  <div>
                    <div style={{ fontWeight: "bold" }}>Media</div>
                    <div>
                      <MediaFBDisplay media={attachments}></MediaFBDisplay>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
              <>
                <div style={{ fontWeight: "bold" }}>Danh sách file</div>
                {attachments.subattachments ? (
                  <Row gutter={20}>
                    {attachments.subattachments.data.map((x) => {
                      return (
                        <Col span={6}>
                          <MediaFBDisplay media={x}></MediaFBDisplay>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <></>
                )}
              </>
            </Card>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Modal>
  );
});
export default PopupEditBlog;
