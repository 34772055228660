import React from "react";
import api from "api";
import { Input, Dropdown, Row, Col, ConfigProvider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { mainColor } from "config";
import ListItem from "client_component/home/list-item";
import { useNavigate } from "react-router-dom";

const ClientBlog = () => {
  const length = 20;
  const [listBlog, setListBlog] = React.useState([]);
  const [listClass, setListClass] = React.useState([]);
  const [listCategory, setListCategory] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [filterBlog, setFilterBlog] = React.useState({
    Status: "publish",
    CategoryIDs: [],
    StartRow: (page - 1) * length,
    Length: length,
    SortCol: "",
    SortType: "",
    Type: "",
  });
  const ourRef = React.useRef(null);
  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const mouseCoords = React.useRef({
    startX: 0,
    scrollLeft: 0,
  });
  const inte = React.useRef();

  const { Search } = Input;

  const navigate = useNavigate();

  const blogTypes = [
    {
      label: "Tất cả",
      key: "",
    },
    {
      label: "Facebook",
      key: "fb_post",
    },
    {
      label: "Website",
      key: "web",
    },
  ];

  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  };
  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
  };

  React.useEffect(() => {
    api.get("/ClientBlog/GetListCategory").then((res) => {
      setListCategory(res.data);
    });
    api.get("/ClientClass/GetListClass?number=4").then((res) => {
      setListClass(res.data);
    });
    getListBlog();
  }, []);

  React.useEffect(() => {
    if (inte.current) {
      clearInterval(inte.current);
    }
    inte.current = setInterval(() => {
      getListBlog();
      clearInterval(inte.current);
    }, 500);
  }, [filterBlog]);

  const getListBlog = () => {
    api.post("/ClientBlog/GetList", filterBlog).then((res) => {
      setListBlog(res.data.data);
      setTotal(res.data.total);
    });
  };

  const setFilter = (value, name) => {
    var filter = { ...filterBlog };
    if (name == "CategoryIDs") filter[name] = [value];
    else filter[name] = value;
    setFilterBlog(filter);
  };

  const pageChange = (e) => {
    setPage(e);
    var filter = { ...filterBlog };
    filter.StartRow = (e - 1) * length;
    setFilterBlog(filter);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div className="container">
        <Row>
          <Col xs={0} sm={0} md={2} lg={4} xl={4}></Col>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <div style={{ display: "flex", height: 40, marginBottom: 20 }}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  padding: "5px 20px",
                  boxSizing: "border-box",
                  borderRadius: 20,
                  backgroundColor: "rgb(245 245 245)",
                  marginRight: 30,
                }}
              >
                <Dropdown
                  menu={{
                    items: blogTypes,
                    selectedKeys: filterBlog.Type,
                    onClick: (e) => {
                      setFilter(e.key, "Type");
                    },
                  }}
                  placement="bottom"
                  trigger={["click"]}
                >
                  <div style={{ display: "flex", width: 100 }}>
                    <div style={{ flex: 1, display: "flex" }}>
                      <div style={{ margin: "auto", whiteSpace: "nowrap" }}>
                        {!filterBlog.Type || filterBlog.Type == "" ? "Tất cả" : filterBlog.Type == "fb_post" ? "Facebook" : "Website"}
                      </div>
                    </div>
                    <div style={{ display: "flex", width: 20 }}>
                      <DownOutlined style={{ margin: "auto" }} />
                    </div>
                  </div>
                </Dropdown>
              </div>
              <div
                className="dont-selector"
                style={{
                  flex: 1,
                  padding: "5px 0px",
                  boxSizing: "border-box",
                  borderRadius: 20,
                  width: "100%",
                  backgroundColor: "rgb(245 245 245)",
                }}
              >
                <div
                  style={{ margin: "0 20px", overflow: "hidden", width: "auto" }}
                  onMouseDown={handleDragStart}
                  onMouseUp={handleDragEnd}
                  onMouseMove={handleDrag}
                  onMouseLeave={handleDragEnd}
                >
                  <div ref={ourRef} className="hide-scroll" style={{ padding: "0 10px", overflowX: "scroll", width: "100%" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setFilter(null, "CategoryIDs");
                        }}
                        style={{
                          padding: "5px 15px",
                          margin: "0 5px",
                          borderRadius: "white",
                          height: 30,
                          borderRadius: 15,
                          boxSizing: "border-box",
                          display: "flex",
                          backgroundColor: filterBlog.CategoryIDs[0] == null ? "#fff" : "transparent",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <div>Tất cả</div>
                      </div>
                      {listCategory.map((x) => {
                        return (
                          <div
                            onClick={() => {
                              setFilter(x.id, "CategoryIDs");
                            }}
                            style={{
                              padding: "5px 15px",
                              margin: "0 5px",
                              borderRadius: "white",
                              height: 30,
                              borderRadius: 15,
                              boxSizing: "border-box",
                              display: "flex",
                              backgroundColor: filterBlog.CategoryIDs[0] == x.id ? "#fff" : "transparent",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <div>{x.name}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={0} sm={0} md={2} lg={4} xl={4}></Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col xs={0} sm={0} md={0} lg={6} xl={6}></Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimary: mainColor,
                    colorPrimaryHover: `#5ac5e7`,
                    colorPrimaryActive: mainColor,
                    lineWidth: 0,
                  },
                },
              }}
            >
              <Search
                style={{ width: "100%" }}
                placeholder="Nhập tên"
                value={filterBlog.Info}
                allowClear
                enterButton="Search"
                size="large"
                onChange={(e) => {
                  setFilter(e.target.value, "Info");
                }}
              />
            </ConfigProvider>
          </Col>
          <Col xs={0} sm={0} md={0} lg={6} xl={6}></Col>
        </Row>
      </div>
      <div className="container">
        <div style={{ boxSizing: "border-box" }}>
          <div>
            <ListItem type="blog" list={listBlog} pagination={true} page={page} total={total} length={length} pageChange={pageChange}></ListItem>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: 40,
            fontWeight: "bold",
            fontFamily: "CustomFont",
            color: mainColor,
            padding: "50px 0",
          }}
        >
          <div>Khóa học</div>
          <div
            className="text-hover"
            style={{ fontSize: 25 }}
            onClick={() => {
              navigate("/course");
            }}
          >
            Xem thêm
          </div>
        </div>
        <div>
          <ListItem type="class" list={listClass}></ListItem>
        </div>
      </div>
    </div>
  );
};

export default ClientBlog;
