import React from "react";
import api from "api";
import { Row, Col, Card, Input, ConfigProvider, Pagination } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { mainColor } from "config";

const { Search } = Input;

const ClientDocument = (props) => {
  const length = 26;
  const [list, setList] = React.useState([]);
  const [listCategory, setListCategory] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState({
    Type: "public",
    StartRow: (page - 1) * length,
    Length: length,
    SortCol: "",
    SortType: "",
    CategoryIDs: [],
    Info: "",
  });
  const ourRef = React.useRef(null);
  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const mouseCoords = React.useRef({
    startX: 0,
    scrollLeft: 0,
  });

  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  };
  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
  };

  React.useEffect(() => {
    api.get("/ClientBlog/GetListCategory").then((res) => {
      setListCategory(res.data);
    });
  }, []);

  React.useEffect(() => {
    getList();
  }, [filter]);

  const getList = () => {
    api.post("/ClientDocument/GetList", filter).then((res) => {
      setList(res.data.data);
      setTotal(res.data.total);
    });
  };

  const handleFilter = (value, name) => {
    var fil = { ...filter };
    if (name == "CategoryIDs") fil[name] = [value];
    else fil[name] = value;
    setFilter(fil);
  };

  const pageChange = (e) => {
    setPage(e);
    var fil = { ...filter };
    fil.StartRow = (e - 1) * length;
    setFilter(fil);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div className="container">
        <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div
              className="dont-selector"
              style={{
                flex: 1,

                padding: "5px 0px",
                boxSizing: "border-box",
                borderRadius: 20,
                backgroundColor: "rgb(245 245 245)",
              }}
            >
              <div
                style={{ margin: "0 20px", overflow: "hidden" }}
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onMouseMove={handleDrag}
                onMouseLeave={handleDragEnd}
              >
                <div ref={ourRef} className="hide-scroll" style={{ padding: "0 10px", overflowX: "scroll", width: "auto" }}>
                  <div style={{ display: "flex" }}>
                    <div
                      onClick={() => {
                        handleFilter(null, "CategoryIDs");
                      }}
                      style={{
                        padding: "5px 15px",
                        margin: "0 5px",
                        borderRadius: "white",
                        height: 30,
                        borderRadius: 15,
                        boxSizing: "border-box",
                        display: "flex",
                        backgroundColor: filter.CategoryIDs[0] == null ? "#fff" : "transparent",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div>Tất cả</div>
                    </div>
                    {listCategory.map((x) => {
                      return (
                        <div
                          onClick={() => {
                            handleFilter(x.id, "CategoryIDs");
                          }}
                          style={{
                            padding: "5px 15px",
                            margin: "0 5px",
                            borderRadius: "white",
                            height: 30,
                            borderRadius: 15,
                            boxSizing: "border-box",
                            display: "flex",
                            backgroundColor: filter.CategoryIDs[0] == x.id ? "#fff" : "transparent",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <div>{x.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimary: mainColor,
                    colorPrimaryHover: `#5ac5e7`,
                    colorPrimaryActive: mainColor,
                    lineWidth: 0,
                  },
                },
              }}
            >
              <Search
                style={{ width: "100%" }}
                placeholder="Nhập tên hoặc chủ đề"
                value={filter.Info}
                allowClear
                enterButton="Search"
                size="large"
                onChange={(e) => {
                  handleFilter(e.target.value, "Info");
                }}
              />
            </ConfigProvider>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          {list.map((x) => {
            return (
              <Col xs={12} sm={12} md={8} lg={6} xl={6} style={{ height: "100%" }}>
                <Card style={{ height: "100%" }}>
                  <Row gutter={20}>
                    <Col span={6}>
                      <a href={x.url} target="_blank">
                        <FileTextOutlined style={{ fontSize: 50, color: mainColor }} />
                      </a>
                    </Col>
                    <Col span={18}>
                      <div style={{ display: "flex" }}>
                        <div style={{ fontWeight: "bold", marginRight: 10 }}>Tên:</div>
                        <div>{x.name}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ fontWeight: "bold", marginRight: 10 }}>Chủ đề:</div>
                        <div>{x.category_name}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>{x.description}</div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 20 }}>
          <Pagination current={page} total={total} onChange={pageChange} pageSize={length} showSizeChanger={false}></Pagination>
        </div>
      </div>
    </div>
  );
};
export default ClientDocument;
