import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, DatePicker, Select, Modal, InputNumber, Button } from "antd";
import dayjs from "dayjs";
import FormItem from "antd/es/form/FormItem";
import FilterSelect from "component/picker/filter_select_picker";

const { Option } = Select;
const { TextArea } = Input;
const VouhcherPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [lstReType, setlstReType] = React.useState([]);
  const [lstFilterReType, setLstFilterReType] = React.useState([]);
  const [lstUser, setlstUser] = React.useState([]);
  const [mode, setmode] = React.useState("edit");
  const form = React.useRef();

  React.useEffect(() => {
    getListREType();
    getListUser();
  }, [props.reload]);

  const getListREType = () => {
    api.get("ReceiptsExpenses/GetListREType").then((res) => {
      setlstReType(res.data);
    });
  };

  const getListUser = () => {
    api.get("ReceiptsExpenses/GetListUser").then((res) => {
      setlstUser(res.data);
    });
  };
  const saveVoucher = (values) => {
    setLoading(true);
    values.day_vouchers = values.day_vouchers.format();
    api
      .post("ReceiptsExpenses/SaveVoucher", values)
      .then((res) => {
        message.success(res.message);
        props.reload();
        setVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(RE) {
      setVisible(true);
      setTimeout(() => {
        setmode("edit");
        if (RE) {
          RE.day_vouchers = dayjs(RE.day_vouchers);
          form.current.setFieldsValue(RE);
        } else {
          setmode("add");
          form.current.resetFields();
          form.current.setFieldsValue({ day_vouchers: dayjs() });
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      width="35%"
      open={visible}
      title={mode == "add" ? "Thêm mới phiếu thu/chi " : "Xem chi tiết phiếu thu/chi "}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
      footer={
        <>
          <Button
            onClick={() => {
              setVisible(false);
              form.current.resetFields();
            }}
            loading={loading}
          >
            Hủy
          </Button>
          <Button
            type={"primary"}
            onClick={() => {
              form.current.submit();
            }}
            loading={loading}
          >
            Lưu
          </Button>
        </>
      }
    >
      <Form ref={form} onFinish={saveVoucher} name="validateOnly" layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="id" name="id" hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormItem label="Loại phiếu " name="voucher_type" rules={[{ required: true, message: "Vui lòng chọn loại phiếu!" }]}>
              <Select
                disabled={mode != "add" ? true : false}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  setLstFilterReType(lstReType.filter((x) => x.type == e));
                }}
              >
                <Option value="receipts">Phiếu thu</Option>
                <Option value="expenses">Phiếu chi</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <Form.Item label="Thời gian " name="day_vouchers" rules={[{ required: true, message: "Vui lòng chọn ngày xử lý!" }]}>
              <DatePicker disabled={mode != "add" ? true : false} placeholder="Chọn ngày" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="apply_type" label="Loại thu/chi" rules={[{ required: true, message: "Vui lòng chọn loại thu/chi!" }]}>
              <Select
                disabled={mode != "add" ? true : false}
                placeholder="Loại thu/chi"
                allowClear
                options={lstFilterReType.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              ></Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="value" label="Giá trị" rules={[{ required: true, message: "Vui lòng nhập giá trị!" }]}>
              <InputNumber
                disabled={mode != "add" ? true : false}
                style={{ width: "100%" }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                step={1000}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="apply_for" label="Người giao dịch" rules={[{ required: true, message: "Vui lòng chọn người giao dịch !" }]}>
              <Select
                disabled={mode != "add" ? true : false}
                placeholder="Người giao dịch"
                allowClear
                options={lstUser.map((item) => {
                  return { value: item.user_name, label: item.name };
                })}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Khóa học" name="class_id">
              <FilterSelect url="Class/SearchClass?search=" getListByIDURL="Class/GetByID?id=" fieldNames={{ value: "id", label: "name" }}></FilterSelect>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Ghi chú " name="description">
              <TextArea rows={3} style={{ marginLeft: "5px" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
export default VouhcherPopup;
