import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Admin from "./Admin";
import Client from "./Client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "component/login";
import User from "component/user/user";
import Class from "component/class";
import Student from "component/student/student";
import ReceiptsExpenses from "component/receipts_expenses";
import { parseJwt } from "common/jwt/jwt_helper";
import { permission } from "config/index";
import { WarningOutlined } from "@ant-design/icons";
import Document from "component/document";
import Homework from "component/homework";
import Target from "component/target";
import Lesson from "component/lesson";
import StudentInClass from "component/class/student_in_class";
import BlogCategory from "component/blog_category";
import Blog from "component/blog";
import Faq from "component/faq";
import REVouhcher from "component/receipts_expenses";
import ReceiptsExpensesType from "component/receipt_expenese_type";
import Home from "client_component/home";
import Achievement from "component/achievement";
import ClientBlog from "client_component/blog";
import ClientClass from "client_component/class";
import ClassDetail from "client_component/class/class_detail";
import BlogDetail from "client_component/blog/blog_detail";
import ClientDocument from "client_component/document";
import Mission from "component/mission";
import About from "client_component/about/index";
import Teacher from "client_component/class/teacher";

const root = ReactDOM.createRoot(document.getElementById("root"));
const ProtectedRoute = () => {
  var path = window.location.pathname.toLowerCase();
  var strSookies = document.cookie;
  var lstCookie = strSookies.split("; ");
  var strToken = lstCookie.find((x) => x.startsWith("Token="));
  if (!strToken) {
    if (path.includes("/admin")) {
      return <Navigate to={"/admin/login"} replace />;
    } else return <Navigate to={"/"} replace />;
  }
  var token = strToken.replace("Token=", "");
  window.userLogin = parseJwt(token);
  var role = window.userLogin.role;
  var myRole = permission.find((x) => x.role == role);
  if (myRole) {
    if (myRole.menu == "ALL" || myRole.menu.includes(path)) {
      return <Outlet />;
    }
  }
  if (path.includes("/admin")) {
    return <Navigate to={"/admin/login"} replace />;
  } else return <Navigate to={"/"} replace />;
};

root.render(
  <Router>
    <Routes>
      <Route exact path="/admin/login" element={<Login />} />
      <Route element={<Client />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About></About>} />
        <Route exact path="/blog" element={<ClientBlog />} />
        <Route exact path="/course" element={<ClientClass />} />
        <Route exact path="/blog/:slug" element={<BlogDetail />} />
        <Route exact path="/course/:slug" element={<ClassDetail />} />
        <Route exact path="/document" element={<ClientDocument />} />
        <Route exact path="/my-course" element={<ClientClass type="my_class" />} />
        <Route exact path="/teacher/:user" element={<Teacher />} />

        <Route
          path="/404"
          element={
            <div
              style={{
                width: "100%",
                height: "60vh",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "800px", height: "200px", padding: 20 }}>
                <WarningOutlined style={{ fontSize: 120, color: "red" }} />
                <div style={{ fontSize: 40 }}>Bạn không có quyền truy cập!</div>
              </div>
            </div>
          }
        />
      </Route>
      <Route element={<Admin />}>
        <Route element={<ProtectedRoute />}>
          <Route exact path="/admin/" element={<Navigate to="/admin/student" replace />} />
          <Route path="/admin/user" element={<User></User>} />
          <Route path="/admin/course" element={<Class></Class>} />
          <Route path="/admin/course/:slug/lesson" element={<Lesson></Lesson>} />
          <Route path="/admin/course/:slug/student" element={<StudentInClass></StudentInClass>} />
          <Route path="/admin/student" element={<Student></Student>} />
          <Route path="/admin/target" element={<Target></Target>} />
          <Route path="/admin/revoucher" element={<ReceiptsExpenses></ReceiptsExpenses>} />
          <Route path="/admin/homework" element={<Homework></Homework>} />
          <Route path="/admin/document" element={<Document></Document>} />
          <Route path="/admin/blog-category" element={<BlogCategory></BlogCategory>} />
          <Route path="/admin/blog" element={<Blog></Blog>} />
          <Route path="/admin/faq" element={<Faq></Faq>} />
          <Route path="/admin/revoucher" element={<REVouhcher></REVouhcher>} />
          <Route path="/admin/revoucher-type" element={<ReceiptsExpensesType></ReceiptsExpensesType>} />
          <Route path="/admin/achievement" element={<Achievement></Achievement>} />
          <Route path="/admin/mission" element={<Mission></Mission>} />
        </Route>
        <Route
          path="/admin/warning"
          element={
            <div
              style={{
                width: "100%",
                height: "60vh",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "800px", height: "200px", padding: 20 }}>
                <WarningOutlined style={{ fontSize: 120, color: "red" }} />
                <div style={{ fontSize: 40 }}>Bạn không có quyền truy cập!</div>
              </div>
            </div>
          }
        />
      </Route>
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
