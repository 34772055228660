import * as React from "react";
import api from "api";
import { Card, Popconfirm, Button, Space, message, Row, Col, Select, Input, Tag, Tooltip } from "antd";
import PopupEditblog from "./popup_edit_blog";
import { EditOutlined, DeleteOutlined, PlusOutlined, RotateRightOutlined, SearchOutlined, BranchesOutlined, CommentOutlined } from "@ant-design/icons";
import { formatLongDate, formatShortDate } from "common/Format2";
import AntTable from "component/common/AntTable";
import PopupRelatedBlog from "./popup_related_blog";
import PopupComment from "./popup_comment";
import FacebookLogin from "react-facebook-login";
import "./style.css";

const Blog = (props) => {
  const [filter, setFilter] = React.useState({});
  const [lstUser, setListUser] = React.useState([]);
  const [listCategory, setListCategory] = React.useState([]);
  const [syncLoading, setSyncLoading] = React.useState(false);
  const [fbConnect, setFBConnect] = React.useState(false);
  const blogPopup = React.useRef();
  const tbBlog = React.useRef();
  const popupRelatedBlog = React.useRef();
  const popupComment = React.useRef();

  React.useEffect(() => {
    getLstBlog();
    getListCategory();
    getListStaff();
    getFBConnect();
  }, []);

  const getFBConnect = () => {
    api.get("Blog/GetFBConnection").then((res) => {
      setFBConnect(res.data);
    });
  };

  const getListStaff = () => {
    api.get("User/GetListStaff").then((res) => {
      setListUser(res.data);
    });
  };

  const getListCategory = () => {
    api.get("/BlogCategory/GetList").then((res) => {
      setListCategory(res.data);
    });
  };

  const getLstBlog = () => {
    tbBlog.current.reload();
  };

  const deleteblog = (id) => {
    api.get("Blog/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công");
      getLstBlog();
    });
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: function (text, record, index) {
        return index + 1;
      },
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      render: (text, record, index) => {
        return (
          <>
            <img style={{ height: "100px" }} src={record.image} />
          </>
        );
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Thể loại",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: function (text, record, index) {
        if (text == "draft") return <Tag>Nháp</Tag>;
        if (text == "publish") return <Tag color="#108ee9">Công khai</Tag>;
      },
    },
    {
      title: "Nguồn",
      dataIndex: "type",
      key: "type",
      render: function (text, record, index) {
        if (text == "web") return <Tag>Website</Tag>;
        if (text == "fb_post") return <Tag color="#108ee9">Facebook</Tag>;
      },
    },
    {
      title: "Người tạo",
      dataIndex: "create_by",
      key: "create_by",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatShortDate(text);
      },
    },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ justifyContent: "center" }}
            type="primary"
            onClick={() => {
              blogPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Tooltip title={"Sửa"}>
              <Button
                type="primary"
                onClick={() => {
                  blogPopup.current.open(record.id);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={"Bài viết liên quan"}>
              <Button
                type="primary"
                onClick={() => {
                  popupRelatedBlog.current.open(record.id);
                }}
              >
                <BranchesOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={"Bình luận"}>
              <Button
                type="primary"
                onClick={() => {
                  popupComment.current.open(record.id);
                }}
              >
                <CommentOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={"Xóa"}>
              <Popconfirm
                title="Bạn có muốn xóa lớp học này không"
                placement="topRight"
                onConfirm={() => {
                  deleteblog(record.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const responseFacebook = (data) => {
    api
      .post("/Blog/ConnectFB", {
        FBID: data.id,
        FBName: data.name,
        AccessToken: data.accessToken,
      })
      .then((res) => {
        getFBConnect();
        message.success("Kết nối thành công");
      });
  };

  const syncPost = () => {
    setSyncLoading(true);
    api
      .get("/Blog/SyncPost")
      .then((res) => {
        getLstBlog();
        setSyncLoading(false);
        message.success("Đồng bộ xong");
      })
      .catch((err) => {
        setSyncLoading(false);
        message.error(err.message);
      });
  };

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={16}>
            <Card>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                {fbConnect ? (
                  <>
                    <div>
                      <div>{`Facebook ID: ${fbConnect.fb_id} `} </div>
                      <div>{`Facebook: ${fbConnect.fb_name} `} </div>
                    </div>
                    <div>
                      <Button
                        type="primary"
                        onClick={() => {
                          syncPost();
                        }}
                        loading={syncLoading}
                      >
                        Đồng bộ bài viết
                      </Button>
                    </div>
                  </>
                ) : (
                  <div style={{ margin: "auto", color: "red" }}> Chưa kết nối facebook</div>
                )}
              </div>
            </Card>
          </Col>
          <Col span={8} style={{ height: "100%" }}>
            <Card style={{ height: "100%" }}>
              <FacebookLogin
                appId="535431744109012"
                autoLoad={false}
                callback={responseFacebook}
                size="small"
                scope="public_profile,pages_show_list,pages_manage_posts, pages_manage_metadata, pages_read_user_content"
                returnScopes={true}
                textButton="&nbsp;&nbsp;Đăng nhập bằng facebook"
              />
            </Card>
          </Col>
        </Row>
      </Card>
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Danh mục</div>
              <Select
                fieldNames={{ label: "name", value: "id" }}
                value={filter.CategoryIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "CategoryIDs");
                }}
                options={listCategory}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Nhân viên</div>
              <Select
                fieldNames={{ label: "name", value: "user_name" }}
                value={filter.UserIDs}
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e, "UserIDs");
                }}
                options={lstUser}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Trạng thái</div>
              <Select
                value={filter.Status}
                onChange={(e) => {
                  paramChange(e, "Status");
                }}
                style={{
                  width: "100%",
                }}
                allowClear
                options={[
                  { value: "draft", label: "Bản nháp" },
                  { value: "publish", label: "Công khai" },
                ]}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                value={filter.status}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  paramChange(e.target.value, "Info");
                }}
                onPressEnter={() => {
                  getLstBlog();
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => {
              getLstBlog();
            }}
          >
            <Space>
              <SearchOutlined /> Tìm kiếm
            </Space>
          </Button>
        </div>
      </Card>
      <Card>
        <AntTable columns={col} ajax="Blog/GetList" customSearch={filter} ref={tbBlog}></AntTable>
        <PopupEditblog ref={blogPopup} reload={getLstBlog}></PopupEditblog>
        <PopupRelatedBlog ref={popupRelatedBlog} reload={getLstBlog} listCategory={listCategory} lstUser={lstUser}></PopupRelatedBlog>
        <PopupComment ref={popupComment}></PopupComment>
      </Card>
    </>
  );
};

export default Blog;
