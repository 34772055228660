import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, DatePicker, Select, Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { imageURL, uploadImageURL } from "config/index";
import axios from "axios";

const { Option } = Select;

const UserInfoPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const form = React.useRef();

  const [defaultImageURL, setdefaultImageURL] = React.useState("");
  const defaultFileList = [
    {
      url: defaultImageURL,
    },
  ];

  React.useEffect(() => {}, [props.reload]);

  const handleChangeImage = (info) => {
    var item = info.file.name;
    setdefaultImageURL(imageURL + item);
    form.current.setFieldsValue({ useravatar: item });
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    var token = localStorage.getItem("token");
    if (!token) token = "";
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data", token },
    };
    fmData.append("image", file);
    try {
      const res = await api.post(uploadImageURL, fmData, config);
      setdefaultImageURL(imageURL + res.data);
      form.current.setFieldsValue({ useravatar: res.data });
      onSuccess("Ok");
    } catch (err) {
      setdefaultImageURL();
      form.current.setFieldsValue({ useravatar: "" });
      onError({ err });
    }
  };

  const finnish = (values) => {
    values.birthday = values.birthday.format();
    api
      .post("/User/UpdateUser", values)
      .then((res) => {
        message.success("Lưu thông tin thành công");
        setVisible(false);
      })
      .catch((err) => {
        message.error("Lưu thất bại");
      });
  };

  React.useImperativeHandle(ref, () => ({
    open(username) {
      setVisible(true);
      setTimeout(() => {
        if (username) {
          api.get("/User/GetByUsername?username=" + username).then((res) => {
            res.data.birthday = res.data.birthday ? dayjs(res.data.birthday) : null;
            form.current.setFieldsValue(res.data);
            setdefaultImageURL(imageURL + res.data.useravatar);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      width="50%"
      open={visible}
      title={"Thông tin nhân viên"}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Form ref={form} name="validateOnly" layout="vertical" onFinish={finnish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item hidden name="user_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Tên nhân viên" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="gender" label="Giới tính">
              <Select placeholder="Giới tính" allowClear>
                <Option value="male">Nam</Option>
                <Option value="female">Nữ</Option>
                <Option value="other">Khác</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phone_number" label="Số điện thoại">
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Sinh nhật " name="birthday">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Địa chỉ " name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col span={12}>
        <Form.Item label="Avatar" name="useravatar">
          <Upload
            disableUpload={true}
            disableRemove={false}
            onChange={handleChangeImage}
            fileList={defaultFileList}
            maxCount={1}
            action={uploadImageURL}
            customRequest={uploadImage}
            listType="picture-card"
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}> Đổi Avatar</div>
            </div>
          </Upload>
        </Form.Item>
      </Col>
    </Modal>
  );
});
export default UserInfoPopup;
