import React from "react";
import { Input, Button, ConfigProvider, message } from "antd";
import api from "api";
import { mainColor } from "config";
import { DataLogin } from "Client";

const InputComment = (props) => {
  const [comment, setComment] = React.useState();
  const dataLogin = React.useContext(DataLogin);

  const userComment = () => {
    if (!dataLogin.isLogin) {
      message.error(" Bạn cần đăng nhập để thực hiện thao tác này");
      return;
    }
    if (!comment || comment == "") {
      message.error(" Vui lòng nhập nội dung bình luận");
      return;
    }
    api
      .post("/Blog/UserComment", { Comment: comment, BlogID: props.blogID, ParentID: props.parentID })
      .then((res) => {
        message.success("Bình luận thành công");
        setComment("");
        if (props.addComment) props.addComment(res.data);
      })
      .catch((err) => {
        message.error("Bình luận thất bại");
      });
  };

  return (
    <div style={{ display: "flex", marginTop: 10 }}>
      <Input
        style={{ flex: 1, marginRight: 20, borderRadius: 20 }}
        size="large"
        placeholder="Viết bình luận"
        onChange={(e) => {
          setComment(e.target.value);
        }}
        value={comment}
      ></Input>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: mainColor,
              colorPrimaryHover: `#5ac5e7`,
              colorPrimaryActive: mainColor,
              lineWidth: 0,
            },
          },
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            userComment();
          }}
          size="large"
          style={{ borderRadius: 20, paddingLeft: 30, paddingRight: 30 }}
        >
          Gửi
        </Button>
      </ConfigProvider>
    </div>
  );
};

export default InputComment;
