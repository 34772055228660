import * as React from "react";
import api from "api";
import { Input, Form, message, Modal } from "antd";

const PopupEditBlogCategory = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();

  const form = React.useRef();

  const saveBlogCategory = (values) => {
    api
      .post("/BlogCategory/Update", values)
      .then((res) => {
        message.success("Thành công");
        props.reload();
        close();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const close = () => {
    form.current.resetFields();
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setTimeout(() => {
        if (id) {
          api.get("/BlogCategory/GetByID?id=" + id).then((res) => {
            form.current.setFieldsValue(res.data);
          });
        } else {
          form.current.setFieldsValue({});
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title="Chỉnh sửa danh mục"
      okText="Lưu"
      onOk={() => {
        form.current.submit();
      }}
      cancelText="Đóng"
      onCancel={() => {
        close();
      }}
      width="30%"
    >
      <Form ref={form} onFinish={saveBlogCategory}>
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item label="Tên " name="name" rules={[{ required: true, message: "Nhập tên danh mục!" }]}>
          <Input style={{ marginLeft: "5px" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default PopupEditBlogCategory;
