import React from "react";
import AntTable from "component/common/AntTable";
import { Modal, Button, Card, Row, Col, Input, Space, Table, message } from "antd";
import { imageURL } from "config";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import api from "api";

const PopupAddStudent = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState();
  const [slug, setSlug] = React.useState();
  const [filter, setFilter] = React.useState();
  const [listSelected, setListSelected] = React.useState([]);

  const tblstudent = React.useRef();

  const getlststudent = () => {
    tblstudent.current.reload();
  };

  const paramChange = (value, name) => {
    var data = { ...filter };
    data[name] = value;
    setFilter(data);
  };

  const addStudent = () => {
    api.post("/Class/AddUsersIntoClass", { Username: listSelected.toString(), CLassID: props.class.id, Type: "student" }).then((res) => {
      message.success("Thêm học sinh thành công");
      props.reload();
      setVisible(false);
    });
  };

  React.useImperativeHandle(ref, () => ({
    open(slug) {
      setVisible(true);
      setSlug(slug);
      setListSelected([]);
      setTimeout(() => {
        getlststudent();
      }, 300);
    },
  }));

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Tên học sinh",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Trường",
      dataIndex: "school",
      key: "school",
    },
    {
      title: "Lớp",
      dataIndex: "class",
      key: "class",
    },
  ];

  return (
    <Modal
      maskClosable={false}
      width="70%"
      open={visible}
      title={"Thêm mới học viên"}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        addStudent();
      }}
      okText="Thêm"
      cancelText="Thoát"
    >
      <Card style={{ marginBottom: 20 }}>
        <Row gutter={20}>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Phone</div>
              <Input
                style={{
                  width: "100%",
                }}
                onPressEnter={(e) => {
                  paramChange(e.target.value, "Phone");
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Email</div>
              <Input
                style={{
                  width: "100%",
                }}
                onPressEnter={(e) => {
                  paramChange(e.target.value, "Email");
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Facebook</div>
              <Input
                style={{
                  width: "100%",
                }}
                onPressEnter={(e) => {
                  paramChange(e.target.value, "Fb");
                }}
              />
            </div>
          </Col>
          <Col span={6}>
            <div>
              <div style={{ fontWeight: "bold" }}>Thông tin</div>
              <Input
                style={{
                  width: "100%",
                }}
                onPressEnter={(e) => {
                  paramChange(e.target.value, "Info");
                }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                getlststudent();
              }}
            >
              <SearchOutlined /> Tìm kiếm
            </Button>
          </Space>
        </div>
      </Card>

      <Card>
        <AntTable
          rowSelection={{
            type: "checkbox",
            onChange: (newSelectedRowKeys) => {
              setListSelected(newSelectedRowKeys);
            },
            hideSelectAll: true,
            selectedRowKeys: listSelected,
          }}
          columns={col}
          rowKey="user_name"
          ajax="User/GetListUserDontJoinClass"
          customSearch={{ ...filter, Slug: slug }}
          ref={tblstudent}
        ></AntTable>
      </Card>
    </Modal>
  );
});
export default PopupAddStudent;
